<section id="faq-section">
    <div class="container">
        <!--********** Headline **********-->
        <div class="row justify-content-center mb-5">
            <div class="col-md-4 text-center">
                <h2>
                    Alles, was du sonst
                    <br class="d-none d-md-block" />
                    noch wissen musst
                </h2>
            </div>
        </div>

        <!--********** Questions **********-->
        <div class="row justify-content-around">
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Welche Kosten kommen sonst noch auf mich zu?</h3>
                    <p class="faq-description">
                        Du benötigst einen DAT- oder Audatex-Account für die Kalkulation.
                        <br />
                        <br />
                        <i>Optional</i>
                        sind Restwertbörsen und Marktwertanalyse-Plattformen.
                        <br />
                        autoiXpert beinhaltet alternativ einen kostenfreien lokalen Restwertverteiler.
                    </p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Was kostet die Kalkulationssoftware?</h3>
                    <p class="faq-description">
                        Das findest du über unsere Preisrechner heraus. Während deines Tests sind Kalkulationen &
                        Fahrzeugbewertungen übrigens kostenfrei.
                    </p>
                    <a class="button-solid" href="/audatex-preisrechner" target="_blank">Audatex-Preise</a>
                    <a class="button-solid" href="/dat-preisrechner" target="_blank">DAT-Preise</a>
                </div>
            </div>
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Muss ich für manche Module extra zahlen?</h3>
                    <p class="faq-description">
                        Nein, in unseren Tarifen sind immer alle Funktionen enthalten.
                        <br />
                        <br />
                        Nur falls du mit Audatex kalkulieren möchtest, brauchst du neben deinem
                        <a href="/audatex-preisrechner" target="_blank">Qapter-Zugang</a>
                        das Audatex-Add-on.
                        <br />
                        <br />
                        Über mehrere Briefpapiere kannst du außerdem zwei Firmen in einem Account verwalten. Statt
                        mehrere volle Büro-Accounts zahlst du dann in einem Büro-Account 69 € pro zusätzlichem
                        Briefpapier.
                    </p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Verlängert sich der Test automatisch?</h3>
                    <p class="faq-description">
                        Nein. Du zahlst natürlich erst, wenn du aktiv einen kostenpflichtigen Account bei uns bestellt
                        hast. Der Test geht nicht automatisch in ein Abo über.
                    </p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Kann ich im Test-Account auch im Team arbeiten?</h3>
                    <p class="faq-description">Ja, lade dazu einfach in den Einstellungen Kollegen ein.</p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Muss ich eine Kreditkarte angeben?</h3>
                    <p class="faq-description">
                        Bei uns bezahlst du nach dem Test bequem per Lastschrift. Für den Test musst du keine
                        Bankverbindung angeben.
                    </p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Ist die Zahl der Nutzer begrenzt?</h3>
                    <p class="faq-description">
                        Nein, du kannst beliebig viele Nutzer zu deinem Büro-Account hinzufügen und ihre Zugriffsrechte
                        in den Einstellungen managen.
                    </p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="faq-container">
                    <h3 class="faq-question">Sind die Preise stabil?</h3>
                    <p class="faq-description">
                        Ja, wir haben unsere Preise bisher stets stabil gehalten und planen auch für die absehbare
                        Zukunft keine Erhöhung.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
