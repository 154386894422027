import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { JUNIOR_DEVELOPER_STRUCTURED_DATA } from './junior-developer.google-structured-data';
import { GoogleStructuredDataComponent } from '../../../shared/components/google-structured-data.component';

@Component({
    selector: 'ax-junior-developer',
    standalone: true,
    imports: [GoogleStructuredDataComponent],
    templateUrl: './junior-developer.component.html',
    styleUrl: '../job-details.scss',
})
export class JuniorDeveloperComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected googleStructuredData = JUNIOR_DEVELOPER_STRUCTURED_DATA;

    constructor() {
        this.titleService.setTitle('Junior Developer | Jobs');
        this.metaService.addTag({
            name: 'description',
            content:
                'Bist du smart, aber hast erst wenig Development-Erfahrung? Starte deine Karriere bei autoiXpert und arbeite an der führenden Software für Kfz-Gutachter.',
        });
    }
}
