import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
    name: 'getWebinarDateLongForm',
    standalone: true,
})
export class GetWebinarDateLongFormPipe implements PipeTransform {
    transform(webinarDate: string | null): unknown {
        if (!webinarDate) return 'lädt...';
        return moment(webinarDate).format('DD. MMMM YYYY');
    }
}
