import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CustomerVoicesService {
    getCustomerVoicesConfig(): [CustomerVoice[], CustomerVoice[]] {
        const voices: CustomerVoice[] = [
            {
                author: 'Sebastian Lindner, SV-Büro Lindner',
                quote: 'Ich habe vorher mit einem älteren Programm gearbeitet, mit autoiXpert wird jetzt schneller reguliert.',
                color: null,
            },
            {
                author: 'Slaven Dragomirovic, SDR-Gutachten GmbH',
                quote: 'Bei unserer Menge an Gutachten war es absolut richtig, zu euch zu wechseln.',
                color: null,
            },
            {
                author: 'Christian Schneider, SV-Büro Schneider',
                quote: 'Egal ob per Mail oder bei euch an der Hotline, euer Support ist einfach der Beste!',
                color: null,
            },
            {
                author: 'Giuseppe Diana, Kfz-SV-Büro Giuseppe Diana',
                quote: 'Oft ist es schwierig, komplexe Dinge simpel zu machen. Das habt ihr aber geschafft!',
                color: null,
            },
            {
                author: 'Arthur Weigel, Kfz-SV-Büro Weigel',
                quote: 'Das Programm ist super, da kann man nie was falsch machen. Es sagt einem immer, wie es weiter geht.',
                color: null,
            },
            {
                author: 'Damian Zyzniewski, SV-Büro Zyzniewski',
                quote: 'Ich denke mir jeden Tag, dass ich mir die richtige Gutachten-Software ausgesucht habe.',
                color: null,
            },
            {
                author: 'Andreas Wesch, Sachverständigenbüro Wesch',
                quote: 'Danke für die schnelle Hilfe letztens. Die Arbeit mit autoiXpert macht einfach Spaß!',
                color: null,
            },
            {
                author: 'Kadir Kilinc, Kfz-Gutachter Kilinc',
                quote: 'autoiXpert ist die beste Software, die ich im Einsatz habe. Hammer Software! Hammer Service!',
                color: null,
            },
            {
                author: 'Nasreddin Toprakli, expertgutachten Berlin',
                quote: 'Die Zusammenarbeit funktioniert tadellos, gute Vorschläge werden ernst genommen & umgesetzt.',
                color: null,
            },
            {
                author: 'Robert Bauer, Kfz-SV-Büro Bauer',
                quote: 'Man ist auch als kleiner Betrieb ohne große Firmen im Hintergrund fähig, ein rundes Gutachten zu schreiben.',
                color: null,
            },
            {
                author: 'Rainer Röschlau, SV-Büro Röschlau',
                quote: 'Sie verbessern Sachen, auf die ich noch gar nicht gekommen bin, die ich aber sehr gut gebrauchen kann.',
                color: null,
            },
        ];

        // Determince Color
        const colors: CustomerVoice['color'][] = ['blue', 'lightpurple', 'mudgreen', 'darkpurple', 'turquoise'];
        voices.forEach((voice, index) => {
            voice.color = colors[index % colors.length];
        });

        // Split into the two rows
        const middleIndex = Math.round(voices.length / 2);
        const firstHalf = voices.slice(0, middleIndex);
        const secondHalf = voices.slice(middleIndex);

        // Duplicate content of each row
        firstHalf.push(...firstHalf);
        secondHalf.push(...secondHalf);

        return [firstHalf, secondHalf];
    }
}

export interface CustomerVoice {
    author: string;
    quote: string;
    color: 'blue' | 'lightpurple' | 'mudgreen' | 'darkpurple' | 'turquoise' | null;
}
