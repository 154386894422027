import $ from 'jquery';

export function registerSmoothScrollingForRelativeLinks() {
    // Select all relative links
    $('a[href^=\\#]').click(function (event) {
        event.preventDefault();

        let href = $(this).attr('href');

        // update the URL
        history.pushState({}, '', href);

        // Allow both "#Preise" and "/Preise.html" which are natural URLs
        if (href) {
            href = href.replace(/\//g, '');
            document.querySelector(href)?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    });
}
