export const JUNIOR_DEVELOPER_STRUCTURED_DATA = {
    '@context': 'http://schema.org',
    '@type': 'JobPosting',
    title: 'Junior Developer Angular, Node.js, TypeScript',
    description:
        'Bist du smart, aber hast erst wenig Development-Erfahrung? Starte deine Karriere bei autoiXpert, lerne von erfahrenen & ambitionierten Team-Kollegen und arbeite an der führenden Software für Kfz-Gutachter.',
    datePosted: '2022-12-27',
    //validThrough                  : "2023-03-31",
    experienceRequirements: {
        '@type': 'OccupationalExperienceRequirements',
        monthsOfExperience: '0',
    },
    applicantLocationRequirements: {
        '@type': 'Country',
        name: 'DE',
    },
    // TELECOMMUTE means that this is a home office job.
    jobLocationType: 'TELECOMMUTE',
    employmentType: 'FULL_TIME',
    hiringOrganization: {
        '@type': 'Organization',
        name: 'autoiXpert GmbH & Co. KG',
        sameAs: 'https://www.autoixpert.de',
        logo: 'https://www.autoixpert.de/images/logo-autoixpert.svg',
    },
    baseSalary: {
        '@type': 'MonetaryAmount',
        currency: 'EUR',
        value: {
            '@type': 'QuantitativeValue',
            minValue: 45_000.0,
            maxValue: 60_000.0,
            unitText: 'YEAR',
        },
    },
    directApply: true,
};
