<section id="terms-of-use-hero-section" class="keynote-hero-section">
    <div class="terms-of-use-hero-header-container">
        <h2>Nutzungsbedingungen</h2>
    </div>
</section>

<section id="contact-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div id="terms-of-use-container" class="col-md-7">
                <h3>§ 1 Anwendungsbereich</h3>
                <div>
                    <p></p>
                    <p>
                        (1) Folgende Nutzungsbedingungen sind Bestandteil aller Verträge (in schriftlicher sowie
                        elektronischer Form) zwischen der autoiXpert GmbH & Co. KG, Binsentalstraße 40A, 89331 Burgau
                        bei Neu-Ulm, (nachfolgend „autoiXpert“ oder „Anbieter“ genannt) und dem Kunden, die über das
                        Portal www.{{ configService.domain }} und / oder über die zur Verfügungstellung der Software
                        autoiXpert für KFZ Sachverständige geschlossen werden.
                    </p>
                    <p>
                        (2) Abweichende AGB der Vertragspartner werden nicht Vertragsbestandteil. Nebenabreden und
                        sonstige Abweichungen von unseren Verträgen, Lizenzbedingungen bzw. von diesen
                        Nutzungsbedingungen bedürfen der Schriftform.
                    </p>
                    <p>(3) Kunden im Sinne der hier vorliegenden Regelungen sind ausschließlich Unternehmer.</p>
                </div>
                <h3>§ 2 Vertragsgegenstand, Vertragsschluss</h3>
                <div>
                    <p>
                        (1) autoiXpert bietet dem Kunden eine online Softwarelösung für eine Vertragslaufzeit an, die
                        dem Kunden als KFZ-Sachverständigen die Organisation und Erstellung von Gutachten mit
                        vorgegebenen Schnittstellen zu Drittanbietern erleichtert. Für die vom Kunden in der
                        Softwarelösung eingepflegten Daten und Inhalte stellt autoiXpert auch Speicherplatz zur
                        Verfügung. Eine Übergabe der Plattform-Software ist nicht Vertragsbestandteil.
                    </p>
                    <p>
                        (2) Der Kunde kann zwischen verschiedenen Angebotsversionen von autoiXpert wählen, die
                        unterschiedliche Nutzungsumfänge und Funktionen der Softwarelösung beinhalten.
                    </p>
                    <p>
                        (3) Die auf der Webseite von autoiXpert dargestellten Angebote stellen ein unverbindliches
                        Angebot im juristischen Sinne dar. Mit der Bestellung über die Webseite erklärt der Kunde
                        verbindlich sein Vertragsangebot. Eingabefehler können im Rahmen des Bestellprozesses vor
                        Absenden der Bestellung mittels der üblichen Tastatur- und Mausfunktionen berichtigt werden. Die
                        Bestätigung des Eingangs der Bestellung erfolgt unmittelbar.
                        <br />
                        Der Anbieter kann das Angebot annehmen, indem der Anbieter dem Kunden
                    </p>
                    <ul>
                        <li>
                            eine schriftliche Auftragsbestätigung oder eine Auftragsbestätigung in Textform übermittelt
                            (E-Mail), wobei insoweit der Zugang der Auftragsbestätigung beim Kunden maßgeblich ist,
                        </li>
                        <li>die Leistung erbringt,</li>
                        <li>nach Abgabe von dessen Bestellung zur Zahlung auffordert.</li>
                    </ul>
                    <p>
                        Eine Zahlungsaufforderung erfolgt auch bei der Mitteilung der Bankdaten an den Kunden oder einer
                        Weiterleitung des Kunden zu einem Zahlungsdiensteanbieter.
                        <br />
                        Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in dem Zeitpunkt zustande,
                        in dem eine der vorgenannten Alternativen zur Annahme zuerst eintritt.
                    </p>
                    <p>(4) Der Vertragstext wird von autoiXpert nicht gespeichert.</p>
                    <p>
                        (5) Ein Vertrag kommt folgendermaßen über die Bestellung per E-Mail, Fax oder Telefon zu Stande:
                        Mit der Bestellung erklärt der Kunde unverbindlich sein Vertragsinteresse oder verbindlich sein
                        Vertragsangebot.
                        <br />
                        (5.1) Vertragsinteresse
                        <br />
                        Die Bestellung des Kunden durch die in Ziffer (5) genannten Mittel stellt ein unverbindliches
                        Angebot des Kunden an autoiXpert zum Abschluss eines Vertrages über die in der Bestellung
                        beschriebene Leistung dar. Nach Eingang der Bestellung übermittelt autoiXpert dem Kunden nach
                        Ermessen eine Nachricht, die den Eingang der Bestellung bestätigt und deren Einzelheiten
                        aufführt (Bestellbestätigung) und die AGB enthält. Diese Bestellbestätigung stellt ein
                        verbindliches Angebot an den Kunden dar. Die Annahme wird durch den Kunden entweder ausdrücklich
                        erklärt oder erfolgt spätestens mit Bezahlung der Leistung innerhalb von 14 Tagen ab Zugang des
                        Angebots. Das von autoiXpert unterbreitete Angebot besteht ab Zugang beim Kunden für eine Dauer
                        von 14 Tagen.
                        <br />
                        (5.2) Vertragsangebot
                        <br />
                        Der Kunde kann in seiner Bestellung auch ausdrücklich verbindlich sein Vertragsangebot erklären.
                        autoiXpert wird dem Kunden nach Ermessen eine Eingangsbestätigung seiner Bestellung übermitteln.
                        Die Annahme ist durch autoiXpert entweder innerhalb von zwei Tagen ausdrücklich erklärt oder
                        erfolgt mit Zahlungsaufforderung oder Leistungserbringung.
                    </p>
                    <p>
                        (6) Der Kunde hat die im Registrierungsformular zum Kundenlogin oder auf anderem Wege durch
                        autoiXpert abgefragten Vertragsdaten vollständig und korrekt anzugeben, wenn und soweit diese
                        nicht als freiwillige Angaben gekennzeichnet sind. Die Angabe von Künstlernamen, Pseudonymen
                        oder sonstigen Phantasiebezeichnungen im Rahmen der Personennameabfrage ist nicht gestattet.
                        Ebenso untersagt ist es, bei der Bestellung fremde oder sonst unzutreffender Angaben anzugeben.
                        Sofern sich die erhobenen Daten nach der Bestellung ändern, ist der Kunde verpflichtet, sein
                        Profil unverzüglich dahingehend zu aktualisieren oder aber autoiXpert die geänderten Daten
                        anderweitig zu übermitteln.
                    </p>
                    <p>
                        (7) Der Kunde muss sein Passwort für das Kundenlogin geheim halten und den Zugang zu seinem
                        Kundenkonto sorgfältig sichern. Der Kunde ist verpflichtet, autoiXpert umgehend zu informieren,
                        wenn es Anhaltspunkte dafür gibt, dass ein Kundenkonto von Dritten missbraucht wurde.
                    </p>
                    <p>
                        (8) Der Anbieter ist berechtigt, aufgrund von Wartungsarbeiten und aus anderen wichtigen Gründen
                        die dauerhafte Nutzung zu unterbrechen, sofern er den Kunden in angemessener Zeit vorab darüber
                        informiert. In dringenden Fällen ist die Vorabmitteilung entbehrlich.
                    </p>
                </div>
                <h3>§ 3 Urheber- und Nutzungsrecht</h3>
                <div>
                    <p>
                        (1) Das Urheber- und ausschließliche Nutzungsrecht für veröffentlichte, von autoiXpert erstellte
                        Objekte (autoiXpert Software inkl. Schnittstellen, Internetseiten, Scripts, Programme, Grafiken)
                        bleibt allein bei autoiXpert.
                    </p>
                    <p>
                        (2) Der Kunde erhält mit Registrierung und mit Vertragsschluss über die Zurverfügungstellung der
                        Software ein einfaches, räumlich unbegrenztes Recht, die Plattform nach dem im Vertrag
                        vereinbarten Umfang für ausschließlich eigene Zwecke für die Dauer des Vertrages zu nutzen.
                        Weitergehende Rechte, insbesondere zur Vervielfältigung über das für die vertragsgemäße Nutzung
                        notwendige Maß hinaus, werden nicht eingeräumt. Etwaige Rechte aus §§ 69 d Abs. 2 und 3, 69 e
                        UrhG bleiben unberührt.
                    </p>
                    <p>
                        (3) Eine Vervielfältigung oder Verwendung von Elementen der Plattform in anderen elektronischen
                        oder gedruckten Publikationen, insbesondere auf anderen Internetseiten, ist ohne ausdrückliche
                        Zustimmung von autoiXpert nicht gestattet. Das umfassende Urheberrecht mit allen Befugnissen
                        nach den §12 bis §27 UrhG an allen im Rahmen der Vertragsanbahnung und einschließlich
                        Gewährleistung und Wartung erstellten Unterlagen, Informationen und Vertragsgegenstände steht
                        ausschließlich autoiXpert zu, es sei denn, es ist schriftlich anders vereinbart.
                    </p>
                </div>
                <h3>§ 4 Durchführung der Leistung, Verantwortlichkeit</h3>
                <div>
                    <p>
                        (1) autoiXpert bietet die technische Möglichkeit zur Verwaltung und Organisation von
                        KFZ-Gutachten mit Zurverfügungstellung von von autoiXpert vorgegebenen Schnittstellen zu
                        Drittanbietern mit der Online- Plattform.
                    </p>
                    <p>
                        (2) Der Kunde hat die Möglichkeit Informationen und Inhalte für seine KFZ-Gutachten und über die
                        vorgegebenen Schnittstellen an Drittanbieter anzugeben.
                    </p>
                    <p>
                        (3) Der Kunde ist dafür verantwortlich, dass die von ihm etwaig gelieferten Inhalte für die
                        Software bzw. für die von ihm mit der Softwarelösung erstellten Gutachten vollumfänglich frei
                        von Rechten Dritter sind und auch aus rechtlicher Sicht für diese Nutzungen geeignet sind und
                        zur Verfügung gestellt werden dürfen. Dasselbe gilt für Daten und Inhalte, die der Kunde in
                        eigener Verantwortung über die Schnittstellen an Drittanbieter übermittelt.
                    </p>
                    <p>
                        (4) Der Kunde stellt autoiXpert von sämtlichen Ansprüchen, einschließlich
                        Schadensersatzansprüchen, frei, die Dritte gegen autoiXpert wegen einer Verletzung ihrer Rechte
                        durch die vom Kunden mit der Softwarelösung von autoiXpert benutzten und übermittelten Inhalte
                        geltend machen. Der Kunde übernimmt alle autoiXpert aufgrund dieser Verletzung von Rechten
                        Dritter entstehenden angemessenen Kosten, einschließlich der für die Rechtsverteidigung
                        entstehenden angemessenen Kosten. Alle weitergehenden Rechte sowie Schadensersatzansprüche von
                        autoiXpert bleiben unberührt.
                    </p>
                    <p>
                        (5) Hat autoiXpert den begründeten Verdacht, dass eine Nutzung des Kunden gegen die
                        Nutzungsbedingungen, Gesetzesbestimmungen oder gegen Rechte Dritter verstößt, darf autoiXpert
                        die betreffende Nutzung bzw. den Zugang zur Software nach vorheriger Information an den Kunden
                        und mit Hinweis auf den Verdacht und ohne dass dies irgendwelche Pflichten für autoiXpert nach
                        sich zieht, sperren.
                    </p>
                    <p>
                        (6) autoiXpert hat das Recht, Angebote und Inhalte der Softwarelösung technisch so zu
                        bearbeiten, aufzubereiten und anzupassen, dass diese auch auf mobilen Endgeräten oder
                        Softwareapplikationen von Dritten dargestellt werden können. autoiXpert behält sich vor, die
                        Softwarelösung zukünftig nach eigener Wahl mit weiteren Schnittstellen zu ergänzen und
                        technische Funktionen zu verbessern. Ebenfalls können nach Wahl von autoiXpert Funktionen
                        entfernt werden, wenn dies nur eine geringfügige Leistungsänderung und keine Änderung der
                        vereinbarten Gesamtfunktionalität bedeutet. Können durch eine Leistungsänderung berechtigte
                        Interessen des Kunden (z.B. bei einer erheblichen Leistungsänderung zum Nachteil des Kunden)
                        nachteilig berührt werden, so teilt autoiXpert diese Leistungsänderung dem Kunden schriftlich
                        oder auf elektronischem Wege vor ihrem Wirksamwerden mit und weist ihn in dieser Mitteilung auf
                        sein nachfolgend geregeltes Sonderkündigungsrecht und die Folgen der Nichtausübung des
                        Kündigungsrechts hin. Dem Kunden steht in diesem Fall das Recht zu, den Vertrag, vorzeitig mit
                        einer Frist von 14 Tagen auf den Zeitpunkt des Wirksamwerdens der Änderung zu kündigen
                        (Sonderkündigungsrecht).
                    </p>
                    <p>
                        (7) Sofern innerhalb der Softwarelösung rechtliche Erklärungen seitens des Kunden oder seiner
                        Endkunden abgegeben werden, erbringt autoiXpert lediglich die technische Darstellung bzw.
                        gegebenenfalls Übermittlung. Für die inhaltliche Vollständigkeit, Richtigkeit und Wirksamkeit
                        ist der Kunde verantwortlich.
                    </p>
                    <p>
                        (8) Der Kunde versichert, ein Handelsgewerbe zu betreiben oder wie Kaufleute im Rechtsverkehr
                        aufzutreten und/oder so behandelt zu werden (z.B. Freiberufler).
                    </p>
                </div>
                <h3>§ 5 Pflichten des Kunden aus anderweitigen Vertragsbeziehungen</h3>
                <div>
                    <p>
                        (1) Der Kunde ist selbst dafür verantwortlich, mit der Softwarelösung erstellte und einsehbare
                        Unterlagen und Informationen, die er zu Zwecken der Beweissicherung, Buchführung usw. benötigt,
                        auf einem von der Plattform unabhängigen Speichermedium zu archivieren.
                    </p>
                    <p>
                        (2) Ein Vertragsverhältnis über die Erstellung von Gutachten oder anderweitigen
                        Leistungserbringungen des Kunden als KFZ-Sachverständigen besteht nur zwischen dem Kunden und
                        seinen Kunden. Im Falle von Streitigkeiten aus derartigen Verträgen oder einer Rückabwicklung
                        derartiger Verträge erfolgt dies direkt zwischen dem Kunden und dem eigenen Kunden.
                    </p>
                    <p>
                        (3) Der Kunde ist verpflichtet, seine Gutachten eigenverantwortlich zu erstellen und haftet für
                        entsprechende rechtliche Informations- und Kennzeichnungspflichten sowie etwaige Aussagen seiner
                        Gutachten und Inhaltsdarstellung und ihm obliegt es diese gemäß seinen eigenen
                        Vertragsbeziehungen zu seinen Kunden ordnungsgemäß zu erbringen.
                    </p>
                    <p>
                        (4) Der Kunde schließt mit Drittanbietern, zu denen er über die Schnittstellen einen Zugang hat,
                        eigene Verträge. autoiXpert bietet mit der Softwarelösung lediglich eine technische Möglichkeit
                        der Verbindung und des Zugangs. Der Kunde bzw. der Drittanbieter sind für die Ausgestaltung der
                        zwischen ihnen vereinbarten Leistung verantwortlich.
                    </p>
                </div>
                <h3>§ 6 Zahlungsbedingungen</h3>
                <div>
                    <p>
                        (1) Die Nutzung der Softwarelösung ist kostenpflichtig und es wird je nach Angebotswahl eine
                        monatliche bzw. jährliche Vergütung vereinbart.
                    </p>
                    <p>(2) Die Zahlung erfolgt im Voraus für den Zahlungszeitraum.</p>
                    <p>
                        (3) Bei Zahlungsverzug des Kunden ist autoiXpert berechtigt, die bereitgestellten Dienste auf
                        Kosten des Kunden zu sperren. Der Kunde bleibt in diesem Fall verpflichtet, die Vergütung zu
                        zahlen.
                    </p>
                    <p>
                        (4) Gerät der Kunde bei monatlicher Abrechnung
                        <br />
                        a) für zwei aufeinander folgende Abrechnungszeiträume mit der Bezahlung der Vergütung bzw. eines
                        überwiegenden Teils hiervon oder
                        <br />
                        b) in einem Zeitraum, der sich über mehrere Monate erstreckt, mit der Bezahlung der Vergütung in
                        Höhe eines Betrages, der den monatlichen Vergütungsbetrag für zwei Monate erreicht, in
                        Rückstand, kann autoiXpert das Vertragsverhältnis ohne Einhaltung einer Frist kündigen. Die
                        Geltendmachung weiterer Ansprüche wegen Zahlungsverzugs bleibt autoiXpert vorbehalten.
                    </p>
                    <p>
                        (5) Ergänzend gilt bei Vertragsverhältnissen, bei denen sich der Kunde zur Zahlung einer
                        monatlichen Vergütung verpflichtet hat, dass autoiXpert im Falle der von ihr vorgenommenen
                        Kündigung berechtigt ist, vom Kunden pauschalierten Schadensersatz in Höhe von 50% der
                        monatlichen Vergütung zu verlangen, die bis zum Ende der Mindestvertragslaufzeit zu zahlen
                        gewesen wäre. Dies gilt nicht, sofern der Kunde nachweist, dass kein Schaden entstanden oder der
                        tatsächlich entstandene Schaden wesentlich geringer als die Pauschale ist.
                    </p>
                </div>
                <h3>§ 7 Datenschutz</h3>
                <div>
                    <p>
                        (1) autoiXpert verpflichtet sich, die gesetzlichen Bestimmungen zum Datenschutz zu beachten. Der
                        Kunde ist für die Verarbeitung und Speicherung der von ihm mit der Softwarelösung eingegebenen
                        und an Drittanbieter übermittelten personenbezogenen Daten im Rahmen eines
                        datenschutzrechtlichen Verantwortlichen verantwortlich. Die Softwarelösung dient zur technischen
                        Unterstützung, eine eigenständige weisungsfreie Verarbeitung dieser Daten durch autoiXpert
                        geschieht nicht.
                    </p>
                    <p>
                        (2) Sofern der Kunde einen Vertrag über Leistungen des Anbieters geschlossen hat, bietet der
                        Anbieter dem Kunden Informationen über eigene ähnliche Leistungen über die beim Vertragsschluss
                        übersandte E-Mailadresse an (§ 7 III UWG). Ein Widerspruch gegen diese Übersendung ist zu jeder
                        Zeit durch den Kunden möglich. Für weitere Informationen und Rechte des Kunden wird auf die
                        Datenschutzerklärung verwiesen.
                    </p>
                </div>
                <h3>§ 8 Haftung</h3>
                <div>
                    <p>
                        (1) Für Schäden, die an anderen Rechtsgütern als dem Leben, Körper oder Gesundheit entstehen ist
                        die Haftung von autoiXpert gegenüber dem Kunden ausgeschlossen, soweit die Schäden nicht auf
                        vorsätzlichem oder grob fahrlässigem Verhalten von autoiXpert, eines von dessen gesetzlichen
                        Vertretern oder eines von dessen Erfüllungsgehilfen beruhen oder das Verhalten auch keine
                        Verletzung von vertragswesentlichen Pflichten ist. Wesentliche Vertragspflichten sind solche
                        Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst
                        ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf.
                        <br />
                        Die genannten Haftungsausschlüsse und Beschränkungen gelten nicht im Fall der Übernahme
                        ausdrücklicher Garantien durch autoiXpert sowie bei Ansprüchen wegen fehlender zugesicherter
                        Eigenschaften oder soweit Ansprüche nach dem Produkthaftungsgesetz betroffen sind.
                    </p>
                    <p>
                        (2) Für die Wiederbeschaffung von Daten gilt, dass autoiXpert nur insoweit haftet, soweit der
                        Kunde alle erforderlichen und zumutbaren Datensicherungsvorkehrungen getroffen und
                        sichergestellt hat, dass die Daten aus Datenmaterial, das in maschinenlesbarer Form
                        bereitgehalten wird, mit vertretbarem Aufwand rekonstruiert werden können.
                    </p>
                    <p>
                        (3) autoiXpert ist bestrebt, den Zugang zu dem Dienst 24 Stunden täglich und sieben Tage pro
                        Woche zur Verfügung zu stellen. Vorübergehende Betriebsunterbrechungen aufgrund der üblichen
                        Wartungsarbeiten, Störungen des Internet bei fremden Providern oder bei fremden Netzbetreibern
                        sowie im Falle höherer Gewalt sind jedoch möglich. Es besteht deshalb kein Anspruch auf eine
                        jederzeitige und ununterbrochene Zugänglichkeit der Plattform.
                    </p>
                </div>
                <h3>§ 9 Laufzeit</h3>
                <div>
                    <p>(1) Das Vertragsverhältnis wird auf unbestimmte Dauer geschlossen.</p>
                    <p>
                        (2) Sofern ein monatliches Zahlungsmodell vereinbart wurde, ist für beide Vertragspartner der
                        Vertrag monatlich kündbar.
                    </p>
                    <p>
                        (3) Sofern ein jährliches Zahlungsmodell vereinbart wurde, ist für beide Vertragspartner der
                        Vertrag mit einer Frist von 14 Tagen zum Ende des Vertragsjahres kündbar.
                    </p>
                    <p>
                        (4) Das Recht der Vertragsparteien zur Kündigung aus wichtigem Grund ohne Einhaltung einer
                        Kündigungsfrist bleibt unberührt.
                    </p>
                    <p>
                        (5) Alle Kündigungen nach diesem Vertrag haben in Textform zu erfolgen. Sofern autoiXpert eine
                        Möglichkeit zur elektronischen Kündigung zur Verfügung stellt, hat der Kunde das Recht, diese
                        ebenfalls zur wirksamen Kündigung zu nutzen.
                    </p>
                </div>
                <h3>§ 10 Schlussbestimmungen</h3>
                <div>
                    <p>
                        (1) Die Beziehungen zwischen den Vertragsparteien regeln sich nach dem in der Bundesrepublik
                        Deutschland geltenden Recht unter Ausschluss des UN- Kaufrechts (United Nations Convention on
                        Contracts for the International Sale of Goods, CISG).
                    </p>
                    <p>(2) Der Gerichtstand für dieses Vertragsverhältnis ist der Sitz des Anbieters.</p>
                    <p>(3) Die Vertragssprache ist deutsch.</p>
                </div>
            </div>
        </div>
    </div>
</section>
