import { Component, Input } from '@angular/core';

@Component({
    selector: 'ax-exclamation-box',
    standalone: true,
    imports: [],
    templateUrl: './exclamation-box.component.html',
    styleUrl: './exclamation-box.component.scss',
})
export class ExclamationBoxComponent {
    @Input() headline: string = 'Bereit zum Durchstarten?';
    @Input() description: string = 'Starte jetzt mit 30 Tagen kostenfreiem Test!';
    @Input() buttonTitle: string = '30 Tage kostenfrei testen';
    @Input() buttonLink: string = '/registrierung';
}
