<section id="total-price-section" class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div class="exclamation-box-container">
                    <div class="label-container">
                        <span class="exclamation-box-sub-title">Gesamtkostenrechner</span>
                        <h3 class="exclamation-box-heading">
                            Preise mit VIN,
                            <br />
                            Kalkulation & Bewertung
                        </h3>
                        <span>Was kostet mich alles unterm Strich?</span>
                        <a class="exclamation-box-button button-solid light" href="/Gesamtkostenrechner">
                            Gesamtpreis berechnen
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
