import { Component, inject } from '@angular/core';
import { PricingFaqComponent } from '../pricing-faq/pricing-faq.component';
import { ExclamationBoxComponent } from '../../../shared/components/exclamation-box/exclamation-box.component';
import { Meta, Title } from '@angular/platform-browser';
import { NgClass } from '@angular/common';
import { SeoService } from '../../../shared/services/seo.service';
import { TotalPriceCalculatorBannerComponent } from '../total-price-calculator-banner/total-price-calculator-banner.component';

type PaymentCycle = 'monthly' | 'annually';

@Component({
    selector: 'ax-modal',
    standalone: true,
    imports: [PricingFaqComponent, ExclamationBoxComponent, NgClass, TotalPriceCalculatorBannerComponent],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss',
})
export class ModalComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly seoService = inject(SeoService);

    protected paymentCycle: PaymentCycle = 'monthly';
    protected audatexAddon = false;

    constructor() {
        this.titleService.setTitle('modal Special');
        this.metaService.addTag({
            name: 'description',
            content: 'Sichere dir 10% Rabatt auf autoiXpert für die ersten drei Monate.',
        });
        this.seoService.setNoIndex();
    }

    protected setPaymentCycle(paymentCycle: PaymentCycle): void {
        this.paymentCycle = paymentCycle;
    }

    protected togglePaymentCycle(event: Event): void {
        if (this.paymentCycle === 'monthly') {
            this.paymentCycle = 'annually';
        } else {
            this.paymentCycle = 'monthly';
        }

        // Stop click on round switch from triggering the toggle twice because the listener sits on the background.
        event.stopPropagation();
    }
}
