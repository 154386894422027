import { Component, inject } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'ax-header',
    standalone: true,
    imports: [],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent {
    protected readonly configService = inject(ConfigService);

    /**
     * Flag that determines if the mobile menu should be shown or not.
     */
    protected mobileMenuOpen = false;
}
