export const INDEX_STRUCTURED_DATA = [
    {
        '@context': 'http://schema.org',
        '@type': 'SoftwareApplication',
        name: 'autoiXpert',
        operatingSystem: 'Windows,Mac,iOS,Android',
        applicationCategory: 'BusinessApplication',
        image: 'https://www.autoixpert.de/images/screenshots/fahrzeugzustand.jpg',
        offers: {
            '@type': 'Offer',
            price: '89.00',
            priceCurrency: 'EUR',
            eligibleRegion: 'DE',
        },
    },
    {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'autoiXpert GmbH & Co. KG',
        address: {
            '@type': 'PostalAddress',
            addressCountry: 'DE',
            addressRegion: 'Bavaria',
            addressLocality: 'Burgau',
            postalCode: '89331',
            streetAddress: 'Binsentalstr. 40A',
        },
        vatID: 'DE308576826',
        email: 'info@autoixpert.de',
        url: 'https://www.autoixpert.de',
        logo: 'https://www.autoixpert.de/images/logo-autoixpert.svg',
    },
];
