<section id="terms-of-use-hero-section" class="keynote-hero-section">
    <div class="terms-of-use-hero-header-container">
        <h2>Datenschutzerklärung</h2>
    </div>
</section>

<section id="contact-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div id="terms-of-use-container" class="col-md-7">
                <h3>I. Allgemeine Angaben und Rechtsgrundlagen</h3>

                <p>
                    Die nachfolgende Erklärung informiert Sie darüber, welche Art von personenbezogenen Daten zu welchem
                    Zweck auf dieser Web-Applikation durch uns als verantwortliche Stelle erhoben werden und in welchem
                    Maße diese Daten Dritten zugänglich gemacht werden.
                </p>

                <h4>1. Verantwortliche Stelle:</h4>
                <p>
                    autoiXpert GmbH & Co. KG, Binsentalstraße 40A, 89331 Burgau, E-Mail: info [at] autoixpert.de,
                    Telefon: +49 8222 91 88 99 0
                </p>

                <p>
                    Geschäftsführer: Herr Andreas Schliefer Handelsregister des Amtsgerichts Memmingen
                    Handelsregisternummer: HRA 12925
                </p>

                <h4>2. Rechtsgrundlagen der Verarbeitung</h4>
                <p>
                    Die Verarbeitung von personenbezogenen Daten bedarf einer Rechtsgrundlage, die wir Ihnen im
                    Folgenden vorstellen möchten.
                    <br />
                    Bei Verarbeitungen von personenbezogener Daten, für die wir eine Einwilligung der betroffenen Person
                    einholen, dient Artikel 6 Absatz 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
                    Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
                    Vertragspartei die betroffene Person ist, erforderlich sind, dient Artikel 6 Absatz 1 lit. b DSGVO
                    als Rechtsgrundlage. Hiervon sind auch Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
                    Maßnahmen erforderlich sind, umfasst.
                    <br />
                    Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung
                    erforderlich ist, denen unser Unternehmen unterliegt, dient Artikel 6 Absatz 1 lit. c DSGVO als
                    Rechtsgrundlage.
                    <br />
                    Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
                    Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen
                    das erstgenannte Interesse nicht, so dient Artikel 6 Absatz 1 lit. f DSGVO als Rechtsgrundlage für
                    die Verarbeitung. Das berechtige Interesse unseres Unternehmens liegt in der Durchführung unserer
                    Geschäftstätigkeit sowie in der Analyse, Optimierung und Erhaltung der Sicherheit unseres
                    Onlineangebotes.
                </p>
                <h3>II. Log-Dateien</h3>

                <p>
                    In den Serverstatistiken werden automatisch solche Daten gespeichert, die der Browser an uns
                    übermittelt im Rahmen unseres berechtigten Interesses der Analyse und aus Sicherheitsgründen
                    (sogenannte „logfiles“).
                </p>

                <p>Dies sind im Einzelnen folgende Daten:</p>
                <ul>
                    <li>Sprache und Version der Browsersoftware</li>
                    <li>verwendetes Betriebssystem und dessen Oberfläche</li>
                    <li>Referrer URL (die zuvor besuchte Seite)</li>
                    <li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
                    <li>Datum und Uhrzeit der Serveranfrage</li>
                    <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                    <li>Inhalt der Anforderung (konkrete Seite)</li>
                    <li>übertragene Datenmenge</li>
                    <li>Zugriffsstatus/ HTTP-Statuscode.</li>
                </ul>
                <p>
                    Diese Daten können wir in der Regel nicht bestimmten Personen zuordnen. Eine Zusammenführung dieser
                    Daten mit anderen Datenquellen wird nicht vorgenommen. Die Daten werden zudem nach einer
                    statistischen Auswertung innerhalb von 7 Tagen gelöscht. Daten, deren weitere Aufbewahrung zu
                    Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der
                    Löschung ausgenommen.
                </p>
                <h3>III. Vertragliche Verarbeitung</h3>

                <p>
                    Die von Ihnen zur Verfügung gestellten personenbezogenen Daten, wie Name, Adresse oder
                    E-Mail-Adresse werden nur intern verwendet, um Ihre Anfragen zu beantworten, Ihre Aufträge zu
                    bearbeiten oder Ihnen Zugang zu bestimmten Informationen zu verschaffen.
                </p>

                <p>
                    Sofern Sie im Rahmen der vertraglichen Nutzung unserer Softwarelösung eigenständig personenbezogene
                    Daten an uns übermitteln, die nicht unser Vertragsverhältnis betreffen und Ihrer eigenen
                    Leistungserbringung als KFZ-Sachverständiger dienen, werden diese Daten nur nach Ihrer Weisung durch
                    uns verarbeitet.
                </p>
                <h3>IV. Kontaktaufnahme</h3>

                <p>
                    Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden Ihre Angaben von uns gespeichert, um Ihre Fragen
                    zu beantworten.
                    <br />
                    Eine Weitergabe an Dritte findet grundsätzlich nicht statt, es sei denn geltende
                    Datenschutzvorschriften rechtfertigen eine Übertragung oder wir dazu gesetzlich verpflichtet sind.
                    Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen. Im Falle des
                    Widerrufs werden Ihre Daten umgehend gelöscht. Ihre Daten werden ansonsten gelöscht, wenn wir Ihre
                    Anfrage bearbeitet haben oder der Zweck der Speicherung entfallen ist. Sie können sich jederzeit
                    über die zu Ihrer Person gespeicherten Daten informieren.
                    <br />
                </p>
                <h3>V. Cookies</h3>

                <p>
                    Diese Seite verwendet Cookies. Cookies sind kleine Textdateien, die auf Ihrem Rechner gespeichert
                    werden und durch welche die Stelle, die den Cookie setzt, bestimmte Informationen zufließen. Sie
                    dienen dazu das Internetangebot nutzerfreundlicher und effektiver zu gestalten oder/und Ihnen die
                    Navigation auf unserer Webseite zu erleichtern.
                    <br />
                    Selbstverständlich können Sie unsere Webseite auch nutzen, ohne dass Sie Cookies akzeptieren. Sie
                    können auch Ihre Browser-Einstellungen entsprechend Ihren Wünschen konfigurieren und insgesamt z. B.
                    die Annahme von Third-Party-Cookies oder allen Cookies ablehnen oder bereits gespeicherte Cookies
                    löschen. Sollten Sie Cookies nicht akzeptieren, beachten Sie bitte, dass in diesem Fall unser
                    Angebot unter Umständen nicht fehlerfrei funktioniert.
                    <br />
                </p>
                <p>Diese Dienste setzen Cookies auf unserer Website:</p>
                <ul>
                    <li>
                        <strong>Google Ads & Google Analytics</strong>
                        <br />
                        Tracking von Sessions & Conversions, Ausspielen von Werbung an unsere Zielgruppen
                    </li>
                    <li>
                        <strong>Facebook</strong>
                        <br />
                        Tracking von Sessions & Conversions, Ausspielen von Werbung an unsere Zielgruppen
                    </li>
                    <li>
                        <strong>YouTube</strong>
                        <br />
                        Streaming von Videos (z. B. Produkt-Demos oder Schulungsvideos)
                    </li>
                    <li>
                        <strong>Microsoft</strong>
                        <br />
                        Analyse des Nutzerverhaltens auf der Website und in unseren Applikationen
                    </li>
                </ul>
                <h3>VI. Newsletter</h3>

                <p>
                    Wenn Sie den von uns angebotenen Newsletter empfangen möchten, benötigen wir von Ihnen eine valide
                    E-Mail-Adresse, die uns die Überprüfung gestattet, dass Sie der Inhaber der angegebenen
                    E-Mail-Adresse sind bzw. deren Inhaber mit dem Empfang des Newsletters einverstanden ist. Wir senden
                    Ihnen nach Angabe der E-Mail-Adresse eine Bestätigungs-E-Mail an die angegebene Adresse, in welcher
                    wir Sie um Bestätigung bitten, dass Sie den Versand des Newsletters wünschen. Wenn Sie dies nicht
                    innerhalb von 24 Stunden bestätigen, werden Ihre Daten in Bezug auf den Newsletterversand
                    automatisch gelöscht. Sofern Sie den Wunsch nach dem Empfang des Newsletters bestätigen, speichern
                    wir Ihre E-Mail-Adresse so lange, bis Sie den Newsletter abbestellen.
                    <br />
                    Wir versenden Newsletter nur mit Ihrer Einwilligung oder einer gesetzlichen Erlaubnis.
                </p>

                <!-- prettier-ignore -->
                <p>
                    Wir arbeiten mit einem Versanddienstleister zusammen. Der
                    Versanddienstleister kann nach eigenen Informationen diese Daten in
                    pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur
                    Optimierung oder Verbesserung der eigenen Services nutzen, z. B. zur
                    technischen Optimierung des Versandes und der Darstellung der
                    Newsletter oder für statistische Zwecke, um zu bestimmen aus welchen
                    Ländern die Empfänger kommen, verwenden. Der Versanddienstleister
                    nutzt die Daten unserer Newsletterempfänger jedoch nicht, um diese
                    selbst anzuschreiben oder an Dritte weiterzugeben.
                    <br/>
                    Der Versand der Newsletter erfolgt mittels „MailChimp“, einer
                    Newsletterversandplattform des US-Anbieters Rocket Science Group,
                    LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die
                    Datenschutzbestimmungen des Versanddienstleisters können Sie hier
                    einsehen:
                    <a href="https://mailchimp.com/legal/privacy/">
                        https://mailchimp.com/legal/privacy/
                    </a>. The Rocket Science Group LLC d/b/a MailChimp ist unter dem
                    Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine
                    Garantie, das europäisches Datenschutzniveau einzuhalten (
                    <a
                        href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active">
                        https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active
                    </a>).
                </p>

                <p>Ihre personenbezogenen Daten werden nicht an Dritte weitergegeben.</p>

                <p>
                    Ihre Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
                    Newsletters können Sie jederzeit widerrufen. Einen Link zur Kündigung des Newsletters finden Sie am
                    Ende eines jeden Newsletters. Wenn Sie sich als Nutzer nur zum Newsletter angemeldet und diese
                    Anmeldung gekündigt haben, werden ihre personenbezogenen Daten gelöscht.
                </p>
                <h3>VII. Registrierung</h3>

                <p>
                    Wir bieten eine Registrierungsmöglichkeit um unsere Softwarelösung zu nutzen. Die im Rahmen der
                    Registrierung eingegebenen personenbezogenen Daten werden an den für die Verarbeitung
                    Verantwortlichen übermittelt. Die Daten werden ausschließlich zum Zweck der internen vertraglichen
                    Verwendung bei uns gespeichert.
                    <br />
                    Bei der Registrierung werden die IP-Adresse des Nutzers sowie Datum und Uhrzeit der Registrierung
                    gegebenenfalls gespeichert. Dies dient dazu einen Missbrauch der Dienste zu verhindern. Eine
                    Weitergabe der Daten an Dritte erfolgt nicht. Eine Ausnahme besteht dann, wenn eine gesetzliche
                    Verpflichtung zur Weitergabe besteht.
                    <br />
                    Die Registrierung der Daten ist für die Bereitstellung von Inhalten oder Leistungen erforderlich.
                    Registrierte Personen haben jederzeit die Möglichkeit die gespeicherten Daten löschen oder abändern
                    zu lassen. Die betroffene Person erhält jederzeit Auskunft über die über ihn gespeicherten
                    personenbezogenen Daten.
                </p>
                <h3>VIII. Weitergabe der Daten an Dritte</h3>

                <p>
                    Wir geben Daten an Dritte weiter, wenn dies zur Vertragserfüllung oder für die Optimierung unserer
                    Leistungen notwendig ist oder wir im Einzelfall dazu gesetzlich verpflichtet sind.
                </p>

                <p>Wir arbeiten mit diesen Drittanbietern zusammen:</p>

                <ul>
                    <li>
                        <strong>Google Ads & Google Analytics</strong>
                        <br />
                        Tracking von Conversions, Ausspielen von Werbung an unsere Zielgruppen
                        <br />
                        Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
                    </li>

                    <li>
                        <strong>YouTube</strong>
                        <br />
                        Streaming von Videos (z. B. Produkt-Demos oder Schulungsvideos)
                        <br />
                        Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
                    </li>

                    <li>
                        <strong>Vimeo</strong>
                        <br />
                        Streaming von Videos (z. B. Produkt-Demos oder Schulungsvideos)
                        <br />
                        Vimeo LLC, 555 West 18th Street, New York, New York 10011, USA
                    </li>

                    <li>
                        <strong>Zendesk</strong>
                        <br />
                        Bereitstellung unseres Wissens-Centers und Verarbeitung von Support-Anfragen
                        <br />
                        Zendesk Inc., 989 Market Street #300, San Francisco, CA 94102, USA
                    </li>

                    <li>
                        <strong>DAT</strong>
                        <br />
                        Bei Anfragen über den DAT-Preisrechner geben wir Ihre Kontaktdaten weiter
                        <br />
                        Deutsche Automobil Treuhand GmbH, Hellmuth-Hirth-Straße 1, 73760 Ostfildern
                    </li>

                    <li>
                        <strong>Audatex</strong>
                        <br />
                        Bei Registrierung für Qapter-iXpert oder für autoiXpert mit Audatex-Modul geben wir Ihre
                        Kontaktdaten weiter
                        <br />
                        Audatex AUTOonline GmbH, Lorenzweg 5, 12099 Berlin
                    </li>

                    <li>
                        <strong>Microsoft</strong>
                        <br />
                        Analyse des Nutzerverhaltens auf der Website und in unseren Applikationen
                        <br />
                        Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA
                    </li>

                    <li>
                        <strong>Hotjar</strong>
                        <br />
                        Analyse des Nutzerverhaltens auf der Website und in unseren Applikationen
                        <br />
                        Hotjar Limited, Dragonara Business Centre 5th Floor, Dragonara Road, Paceville St Julian's STJ
                        3141 Malta
                    </li>

                    <li>
                        <strong>Pipedrive</strong>
                        <br />
                        Bei der Registrierung für unsere Software-Applikationen werden Kontaktdaten automatisch an unser
                        CRM-System Pipedrive übertragen
                        <br />
                        Pipedrive OÜ, Mustamäe tee 3a, Tallinn, 10615, Estland
                    </li>

                    <li>
                        <strong>Facebook</strong>
                        <br />
                        Tracking von Conversions, Ausspielen von Werbung an unsere Zielgruppen
                        <br />
                        Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland
                    </li>

                    <li>
                        <strong>Sentry</strong>
                        <br />
                        Fehleranalyse in unseren Software-Applikationen
                        <br />
                        Functional Software, Inc. dba Sentry, Attn: Legal Department, 45 Fremont Street, 8th Floor, San
                        Francisco, CA 94105-2250, USA
                    </li>
                </ul>

                <p>
                    Im Nutzungsverhältnis unserer Softwarelösung veranlassen Sie z. B. durch unsere Schnittstellen die
                    Weitergabe der von Ihnen gewählten personenbezogenen Daten. Dies geschieht ausdrücklich nur mit
                    Ihrer eigenen Bestätigung.
                </p>
                <h3>IX. Rechte der betroffenen Person</h3>

                <p>
                    Sie haben ein Recht auf Auskunft über die bei uns über Sie gespeicherten personenbezogenen Daten.
                    Nach den gesetzlichen Bestimmungen haben Sie ebenfalls ein Recht auf Berichtigung unrichtiger Daten,
                    Sperrung, Datenübertragbarkeit und Löschung Ihrer personenbezogenen Daten. Senden Sie hierzu eine
                    E-Mail an uns mit dem Betreff "Datenschutz".
                    <br />
                    Sie haben ebenfalls ein Recht auf Beschwerde bei einer Datenschutz-Aufsichtsbehörde, sofern Sie der
                    Ansicht sind, dass eine Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
                    gesetzlichen Bestimmungen verstößt.
                </p>

                <p>
                    Sofern Sie eine Einwilligung erteilt haben, haben Sie das Recht, die Einwilligung jederzeit zu
                    widerrufen. Hiervon wird die Rechtsmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                    erfolgten Verarbeitung nicht berührt.
                </p>

                <p>
                    Sie können der künftigen Verarbeitung Ihrer personenbezogenen Daten entsprechend den gesetzlichen
                    Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für
                    Zwecke der Direktwerbung erfolgen.
                </p>
                <h3>X. Speicherdauer der personenbezogenen Daten</h3>

                <p>
                    Wir speichern personenbezogene Daten für die Dauer der jeweiligen gesetzlichen Aufbewahrungsfrist.
                    Nach Ablauf der Frist erfolgt eine routinemäßige Löschung der Daten, sofern nicht eine
                    Erforderlichkeit für eine Vertragsanbahnung oder die Vertragserfüllung besteht. Sofern die Daten der
                    Nutzer nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind,
                    wird deren Verarbeitung eingeschränkt. Die Daten werden dementsprechend gesperrt und nicht für
                    andere Zwecke verarbeitet. Das gilt z. B. für Daten der Nutzer, die aus handels- oder
                    steuerrechtlichen Gründen aufbewahrt werden müssen.
                </p>
                <h3>XI. Sicherheitsinformationen</h3>
                <p>
                    Wir sind sehr bemüht, Ihre personenbezogenen Daten durch Ergreifung aller technischen und
                    organisatorischen Möglichkeiten so zu verarbeiten, dass die Vorschriften der Datenschutzgesetze
                    eingehalten werden und um damit diese Daten zu schützen. Unsere Webseite bzw. die Kommunikation über
                    unsere Webseite mit uns ist per HTTPS verschlüsselt.
                </p>
                <h3>XII. Rechte des Nutzers</h3>
                <p>
                    Sie haben als Nutzer das Recht auf Auskunft, Berichtigung und Löschung der bei autoiXpert
                    gespeicherten Daten (ausgenommen wir sind gesetzlich zur Aufbewahrung verpflichtet, z. B. bei
                    Zahlungsverkehr).
                    <a href="/kontakt">Kontaktieren Sie uns</a>
                    für weitere Infos.
                </p>
                <p>Stand: 15.04.2024</p>
            </div>
        </div>
    </div>
</section>

<script src="/js/index.js"></script>
