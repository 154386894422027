import { afterNextRender, Component, inject, makeStateKey, PLATFORM_ID, TransferState, ViewChild } from '@angular/core';
import { CustomerVoicesService } from '../../shared/services/customer-voices.service';
import { HammerModule, Meta, Title } from '@angular/platform-browser';
import $ from 'jquery';
import { registerSmoothScrollingForRelativeLinks } from '../../shared/lib/smooth-scrolling';
import { HeroVideoPlayerComponent } from '../../shared/components/hero-video-player/hero-video-player.component';
import { EventConfig, getEventsConfig } from '../../shared/lib/get-events-config';
import { isPlatformServer } from '@angular/common';
import { GoogleStructuredDataComponent } from '../../shared/components/google-structured-data.component';
import { INDEX_STRUCTURED_DATA } from './index.google-structured-data';
import moment from 'moment';
import { EventDatePipe } from '../../shared/pipe/event-date.pipe';

@Component({
    selector: 'ax-index',
    standalone: true,
    imports: [HeroVideoPlayerComponent, GoogleStructuredDataComponent, HammerModule, EventDatePipe],
    templateUrl: './index.component.html',
    styleUrl: './index.component.scss',
})
export class IndexComponent {
    protected readonly customerVoicesService = inject(CustomerVoicesService);
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly platformId = inject(PLATFORM_ID);
    transferState = inject(TransferState);

    eventsStateKey = makeStateKey<Array<EventConfig>>('events');

    @ViewChild(HeroVideoPlayerComponent) videoPlayer!: HeroVideoPlayerComponent;

    protected googleStructuredData = INDEX_STRUCTURED_DATA;
    protected customerVoices = this.customerVoicesService.getCustomerVoicesConfig();

    protected shownEvents?: Array<EventConfig>;
    protected allEvents?: Array<EventConfig>;

    constructor() {
        this.titleService.setTitle('autoiXpert | Software für KFZ-Sachverständige');
        this.metaService.addTag({
            name: 'description',
            content:
                'Die Software für KFZ-Sachverständige, der in Deutschland über 1.000 Sachverständige vertrauen. Gutachten für Haftpflicht, Kasko, Bewertungen & mehr.',
        });

        if (isPlatformServer(this.platformId)) {
            this.allEvents = getEventsConfig();
            this.shownEvents = this.allEvents.slice(0, 3); // Display 3 events at max
            this.transferState.set(this.eventsStateKey, this.allEvents);
        } else {
            this.shownEvents =
                this.transferState
                    .get(this.eventsStateKey, [])
                    // Only display events that are either today or in the future.
                    ?.filter((event) => moment(event.date).isAfter(moment().startOf('day')))
                    .slice(0, 3) || [];
        }

        // Only runs in client
        afterNextRender(() => {
            // Screenshot Carousel
            this.duplicateNecessaryCarouselCounters();
            this.registerScreenshotCarouselClickHandlers();
            this.registerScreenshotCounterClickHandlers();
            this.registerScreenshotCarouselSwipeHandlers();
            // Select the first screenshot
            this.selectScreenshot(0);

            // When clicking on "Funktionen" in the footer, scroll to the tour instead of reloading the page.
            registerSmoothScrollingForRelativeLinks();
        });
    }

    //*****************************************************************************
    //  Screenshot carousel
    //****************************************************************************/
    protected duplicateNecessaryCarouselCounters() {
        const numberOfScreenshots = $('.intro-screenshot-container').length;

        // Create new position counter for each screenshot except the first. That one uses the existing counter from the HTML.
        const template = $('.intro-screenshots-counter');
        const parent = template.parent();
        for (let i = 1; i < numberOfScreenshots; i++) {
            parent.append(template.clone());
        }
    }

    protected registerScreenshotCarouselClickHandlers() {
        $('#screenshot-arrow-left-container').click(() => {
            // Don't use the arrows if the visitor is on mobile
            if (window.matchMedia('max-width: 1025px').matches) return;

            this.spinScreenshotcarousel({ direction: 'backward' });
        });
        $('#screenshot-arrow-right-container').click(() => {
            // Don't use the arrows if the visitor is on mobile
            if (window.matchMedia('max-width: 1025px').matches) return;

            this.spinScreenshotcarousel({ direction: 'forward' });
        });
    }

    protected registerScreenshotCounterClickHandlers() {
        const screenshotCounters = $('.intro-screenshots-counter');

        screenshotCounters.click((event) => {
            const index = screenshotCounters.index($(event.currentTarget));
            this.selectScreenshot(index);
        });
    }

    protected spinScreenshotcarousel(options: any) {
        if (!options || !options.direction) {
            throw Error('An options object must be provided. options = {direction: string}');
        }

        const screenshotContainers = $('.intro-screenshot-container');
        const currentlyActiveScreenshot = screenshotContainers.filter('.active');
        let nextScreenshot = currentlyActiveScreenshot.next('.intro-screenshot-container');
        let previousScreenshot = currentlyActiveScreenshot.prev('.intro-screenshot-container');

        // If the end of the carousel is reached, take shots from the other end
        if (nextScreenshot.length === 0) {
            nextScreenshot = screenshotContainers.first();
        }
        if (previousScreenshot.length === 0) {
            previousScreenshot = screenshotContainers.last();
        }
        const newActiveScreenshot = options.direction === 'forward' ? nextScreenshot : previousScreenshot;

        this.selectScreenshot(newActiveScreenshot.index());

        // Change "next" button when there isn't another image
        if (newActiveScreenshot.next('.intro-screenshot-container').length === 0) {
            $('#screenshot-arrow-right-container').addClass('reached-last-image');
        } else {
            $('#screenshot-arrow-right-container').removeClass('reached-last-image');
        }
    }

    protected selectScreenshot(index: number) {
        const screenshots = $('.intro-screenshot-container');
        // Clear all classes associated with this carousel
        screenshots.removeClass('active upcoming hidden left right');

        // Find new active screenshot by carousel
        const newActiveScreenshot = $(screenshots[index]);

        newActiveScreenshot.addClass('active');

        // The same as above, but now relative to a new active screenshot.
        let newNextScreenshot = newActiveScreenshot.next('.intro-screenshot-container');
        let newPreviousScreenshot = newActiveScreenshot.prev('.intro-screenshot-container');
        // If the end of the carousel is reached, take shots from the other end
        if (newNextScreenshot.length === 0) {
            newNextScreenshot = screenshots.first();
        }
        if (newPreviousScreenshot.length === 0) {
            newPreviousScreenshot = screenshots.last();
        }

        newPreviousScreenshot.addClass('upcoming left');
        newNextScreenshot.addClass('upcoming right');
        screenshots.filter(':not(.active):not(.upcoming)').addClass('hidden');

        // Mark the counter as active too
        const screenshotCounters = $('.intro-screenshots-counter');

        // Deactivate all
        screenshotCounters.removeClass('active');
        // Activate the right one
        $(screenshotCounters[index]).addClass('active');
    }

    protected registerScreenshotCarouselSwipeHandlers() {
        // Prevent dragging, otherwise swipe won't work correctly.
        document.querySelectorAll('img.intro-screenshot').forEach((img) =>
            img.addEventListener('dragstart', (event) => {
                event.preventDefault();
            }),
        );

        const screenshotCarouselContainer = document.getElementById('intro-screenshots-container');

        if (screenshotCarouselContainer) {
            const hammerInstance = new Hammer(screenshotCarouselContainer);
            hammerInstance.on('swipeleft', () => {
                this.spinScreenshotcarousel({ direction: 'forward' });
            });
            hammerInstance.on('swiperight', () => {
                this.spinScreenshotcarousel({ direction: 'backward' });
            });
        }
    }
}
