import { animate, group, style, transition, trigger } from '@angular/animations';

export function fadeInAndSlideVerticallyAnimation({
    name = 'fadeInAndSlide',
    fast = false,
    duration = 300,
}: {
    duration?: number;
    fast?: boolean;
    name?: string;
} = {}) {
    const translationOffset = fast ? 50 : 100;

    return trigger(name, [
        transition(':enter', [
            style({
                opacity: 0,
                transform: `translate3d(0, -${translationOffset}%, 0)`,
            }),
            group([
                animate(
                    `${duration}ms ease-in-out`,
                    style({
                        opacity: 1,
                    }),
                ),
                animate(
                    `${duration}ms ease-in-out`,
                    style({
                        transform: 'translate3d(0, 0, 0)',
                    }),
                ),
            ]),
        ]),
        transition(':leave', [
            group([
                animate(
                    `${duration}ms ease-in-out`,
                    style({
                        opacity: 0,
                    }),
                ),
                animate(
                    `${duration}ms ease-in-out`,
                    style({
                        transform: `translate3d(0, ${translationOffset}%, 0)`,
                    }),
                ),
            ]),
        ]),
    ]);
}
