import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
    name: 'getWebinarDateLongFormWithWeekday',
    standalone: true,
})
export class GetWebinarDateLongFormWithWeekdayPipe implements PipeTransform {
    transform(webinarDate: string | null): unknown {
        if (!webinarDate) return 'lädt...';
        return moment(webinarDate).format('dddd, DD. MMMM YYYY');
    }
}
