import {WebinarTargetGroupLevel} from './components/webinar-target-group/webinar-target-group.component';

/**
 * Depending on the given target group builds a meta description string like the following
 * example: 'Fachvorträge mit Wissen für Kfz-Sachverständige - Einsteiger & Fortgeschrittene'
 */
export function getMetaDescriptionForTargetGroup(targetGroup: WebinarTargetGroupLevel): string {
    let targetGroupString = '';
    if (targetGroup.beginner && targetGroup.intermediate && targetGroup.professional) {
        targetGroupString = 'Einsteiger, Fortgeschrittene & Profis';
    } else {
        targetGroupString = Object.entries(targetGroup)
            .reduce((acc, [key, val]) => {
                if (val) {
                    acc.push(getTargetGroupLabel(key as keyof WebinarTargetGroupLevel));
                }
                return acc;
            }, [] as string[])
            .join(' & ');
    }

    return 'Fachvorträge mit Wissen für Kfz-Sachverständige - ' + targetGroupString;
}

function getTargetGroupLabel(key: keyof WebinarTargetGroupLevel): string {
    switch (key) {
        case 'beginner':
            return 'Einsteiger';
        case 'intermediate':
            return 'Fortgeschrittene';
        case 'professional':
            return 'Profis';
    }
}
