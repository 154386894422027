<section id="signup-section" class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div class="exclamation-box-container">
                    <h3 class="exclamation-box-heading">{{ headline }}</h3>
                    <div>{{ description }}</div>
                    <a class="exclamation-box-button button-solid light" href="{{ buttonLink }}">
                        {{ buttonTitle }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
