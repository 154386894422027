import { Component } from '@angular/core';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'ax-page-with-header-and-footer',
    standalone: true,
    imports: [FooterComponent, HeaderComponent, RouterOutlet],
    templateUrl: './page-with-header-and-footer.component.html',
})
export class PageWithHeaderAndFooterComponent {}
