import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NgClass } from '@angular/common';
import { fadeInAndSlideVerticallyAnimation } from '../../shared/animations/fade-in-and-slide-vertically.animation';
import { PricingFaqComponent } from './pricing-faq/pricing-faq.component';
import { ExclamationBoxComponent } from '../../shared/components/exclamation-box/exclamation-box.component';
import { TotalPriceCalculatorBannerComponent } from './total-price-calculator-banner/total-price-calculator-banner.component';

type PaymentCycle = 'monthly' | 'annually';

@Component({
    selector: 'ax-preise',
    standalone: true,
    imports: [NgClass, PricingFaqComponent, ExclamationBoxComponent, TotalPriceCalculatorBannerComponent],
    templateUrl: './preise.component.html',
    styleUrl: './preise.component.scss',
    animations: [
        fadeInAndSlideVerticallyAnimation(),
        fadeInAndSlideVerticallyAnimation({
            name: 'fadeInAndSlideFast',
            fast: true,
        }),
    ],
})
export class PreiseComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    protected paymentCycle: PaymentCycle = 'monthly';
    protected audatexAddon = false;

    constructor() {
        this.titleService.setTitle('Preise');
        this.metaService.addTag({
            name: 'description',
            content:
                'Das beste Preis-Leistungsverhältnis für Kfz-Sachverständigen-Software? Das finden Sie bei autoiXpert.',
        });
    }

    protected setPaymentCycle(paymentCycle: PaymentCycle) {
        this.paymentCycle = paymentCycle;
    }

    protected togglePaymentCycle(event: Event) {
        if (this.paymentCycle === 'monthly') {
            this.paymentCycle = 'annually';
        } else {
            this.paymentCycle = 'monthly';
        }

        // Stop click on round switch from triggering the toggle twice because the listener sits on the background.
        event.stopPropagation();
    }

    protected setAudatexAddon(useAudatexAddon: boolean) {
        this.audatexAddon = useAudatexAddon;
    }

    protected toggleAudatexAddon(event: Event) {
        this.audatexAddon = !this.audatexAddon;
        // Stop click on round switch from triggering the toggle twice because the listener sits on the background.
        event.stopPropagation();
    }
}
