<section>
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <h1>Teilnahmebedingungen</h1>
                <p>autoiXpert-Gewinnspiel auf Facebook</p>
                <h3>Rechtliches</h3>
                <p>
                    Teilnahme nur ab 18 Jahre. Eine Barauszahlung des Gewinnes ist nicht möglich. Mit der Teilnahme am
                    Gewinnspiel erklärt sich der Teilnehmer damit einverstanden, im Falle eines Gewinns namentlich
                    veröffentlicht zu werden.
                    <br />
                    Die Gewinner müssen sich innerhalb von zwei Tagen nach Gewinnerbekanntgabe melden, damit autoiXpert
                    ihnen ihren Gewinn zusenden kann. Sonst wird der jeweilige Gewinn neu ausgelost.
                </p>

                <h3>Datenschutz</h3>
                <p>
                    Der Teilnehmer erklärt sich damit einverstanden, dass die erhobenen Daten für die Dauer der Aktion
                    gespeichert und nur zur Gewinnzusendung genutzt werden. Nach Abschluss der Aktion werden alle im
                    Rahmen des Gewinnspiels erhobenen Daten gelöscht.
                </p>

                <h3>Freistellungsklausel</h3>
                <p>
                    Die Beiträge (Bilder, Links und Texte) der Teilnehmer dürfen keine Beleidigungen, falsche Tatsachen,
                    Wettbewerbs-, Marken- oder Urheberrechtsverstöße enthalten.
                    <br />
                    Sollten dennoch Dritte Ansprüche wegen Verletzung ihrer Rechte geltend machen, so stellt der
                    Teilnehmer autoiXpert von allen Ansprüchen frei. Mit der Teilnahme am Gewinnspiel verpflichtet sich
                    jeder Teilnehmer für seine etwaigen Rechtsverstöße persönlich zu haften.
                </p>

                <h3>Änderung der Teilnahmebedingungen / Beendigung des Gewinnspiels</h3>
                <p>
                    autoiXpert behält sich vor, die Teilnahmebedingungen jederzeit zu ändern, beziehungsweise das
                    Gewinnspiel ganz oder in Teilen aus wichtigem Grund ohne Vorankündigung zu unterbrechen oder zu
                    beenden.
                    <br />
                    autoiXpert weist ausdrücklich darauf hin, dass dieses Gewinnspiel in keinem Zusammenhang mit
                    Facebook steht und Facebook nicht als Ansprechpartner für dieses Gewinnspiel zur Verfügung steht.
                </p>

                <h3>Gewinnspielzeitraum</h3>
                <p>Das Gewinnspiel startet am 01.12.2020 und endet am 12.12.2020 um 12:12 Uhr.</p>

                <h3>Auswertung</h3>
                <p>
                    Das Gewinnspiel wird durch unsere Jury, bestehend aus den Personen Mark Langer, Steffen Langer,
                    Andreas Schliefer ausgewertet. Die drei Fotos mit den meisten Likes auf Facebook gewinnen einen
                    Preis.
                </p>

                <h3>Gewinn</h3>
                <p>Der Gewinnspielpreis besteht aus 3 mal 30€-Amazon-Gutscheinen.</p>
            </div>
        </div>
    </div>
</section>
