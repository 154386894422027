<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        In diesem Webinar werden die häufigsten Fehlerquellen und möglichen Folgen bei der Schadenkalkulation
        aufgedeckt. Das Seminar beleuchtet speziell die Fallstricke bei der Schadenkalkulation mit DAT und Audatex und
        gibt Ihnen wertvolle Hinweise, wie Sie diese vermeiden können. Darüber hinaus werden allgemeine Fallstricke bei
        der Gutachtenerstellung behandelt, um Ihnen einen umfassenden Überblick über potenzielle Fehlerquellen zu geben.
        Nehmen Sie an diesem Webinar teil, um Ihre Kenntnisse zu erweitern und Ihre Schadenkalkulation zu optimieren, um
        mögliche Probleme zu vermeiden.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die die Grundlagen zum Thema Schadenkalkulation kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
