<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Ein Kaskoschadengutachten erfordert im Vergleich zur Erstellung eines Haftpflichtschadengutachtens eine genaue
        Kenntnis der Vertragssituation zwischen Kunde und Versicherung. Grundlage dafür sind die Allgemeinen Bedingungen
        für die KFZ- Versicherung (AKB) sowie die individuellen Verträge. Teil 1 des Kasko-Seminars behandelt die
        grundlegenden Inhalte der AKBs.
        <a href="fachwebinare/kasko-2-kohl?webinarDate=MjAyMy0wOC0wMVQxNzowMDowMC4wMDArMDI6MDA=">Teil 2</a>
        des Webinars behandelt die praktischen Bedeutungen für Kaskogutachten.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die die Grundlagen zum Thema Kaskogutachten und AKB kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
