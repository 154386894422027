import { Component, Input } from '@angular/core';

export interface WebinarTargetGroupLevel {
    beginner: boolean;
    intermediate: boolean;
    professional: boolean;
}

@Component({
    selector: 'ax-webinar-target-group',
    standalone: true,
    imports: [],
    templateUrl: './webinar-target-group.component.html',
    styleUrl: './webinar-target-group.component.scss',
})
export class WebinarTargetGroupComponent {
    @Input() description: string = '';
    @Input() targetGroupLevel: WebinarTargetGroupLevel = {
        beginner: true,
        intermediate: true,
        professional: true,
    };
}
