<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Egal ob auf Basis eines Prüfberichts oder einer Rechnungsprüfung: Immer wieder werden Sachverständige durch Ihre
        Auftraggeber oder deren Rechtsanwälte dazu aufgefordert, zu Sachverhalten, die die gegnerische Versicherung
        abgezogen hat, Stellung zu nehmen. Im Seminar werden nach einer Grundlagenanalyse die erforderlichen Inhalte für
        eine Stellungnahme sowie die Argumentationsketten aufgegriffen und anhand praktischer Beispiele diskutiert. Die
        Umsetzung wird anhand der Funktion „Stellungnahme“ am System autoiXpert aufgezeigt.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Grundlagen zum Schreiben von Stellungnahmen erwerben oder vertiefen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
