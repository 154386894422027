import { Component, HostListener } from '@angular/core';

@Component({
    selector: 'ax-hero-video-player',
    standalone: true,
    imports: [],
    templateUrl: './hero-video-player.component.html',
    styleUrl: './hero-video-player.component.scss',
})
export class HeroVideoPlayerComponent {
    @HostListener('window:keydown.Escape')
    keyDownPressed() {
        this.closeYoutubePlayer();
    }

    protected showYoutubePlayer: boolean = false;

    openYoutubePlayer() {
        this.showYoutubePlayer = true;
    }

    closeYoutubePlayer() {
        this.showYoutubePlayer = false;
    }
}
