export const SENIOR_DEV_STRUCTURED_DATA = {
    '@context': 'http://schema.org',
    '@type': 'JobPosting',
    title: 'Senior Developer Angular, Node.js, TypeScript',
    description:
        'Stell mit uns die Branche der KFZ-Sachverständigen auf den Kopf! Als Senior Engineer verstehst du die Probleme unserer Kunden, entwirfst mit unseren Designern Lösungen und setzt sie in Angular & Node.js um.',
    datePosted: '2022-12-30',
    //validThrough                  : "2023-03-31",
    experienceRequirements: {
        '@type': 'OccupationalExperienceRequirements',
        monthsOfExperience: '60',
    },
    applicantLocationRequirements: {
        '@type': 'Country',
        name: 'DE',
    },
    // TELECOMMUTE means that this is a home office job.
    jobLocationType: 'TELECOMMUTE',
    employmentType: 'FULL_TIME',
    hiringOrganization: {
        '@type': 'Organization',
        name: 'autoiXpert GmbH & Co. KG',
        sameAs: 'https://www.autoixpert.de',
        logo: 'https://www.autoixpert.de/images/logo-autoixpert.svg',
    },
    baseSalary: {
        '@type': 'MonetaryAmount',
        currency: 'EUR',
        value: {
            '@type': 'QuantitativeValue',
            minValue: 75_000.0,
            maxValue: 95_000.0,
            unitText: 'YEAR',
        },
    },
    directApply: true,
};
