import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ax-jobs',
    standalone: true,
    imports: [],
    templateUrl: './jobs.component.html',
    styleUrl: './jobs.component.scss',
})
export class JobsComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Jobs');
        this.metaService.addTag({
            name: 'description',
            content: 'Werde Teil unseres Teams und arbeite an der führenden Software für Kfz-Gutachter.',
        });
    }
}
