<!--============================================-->
<!-- Hero -->
<!--============================================-->
<section id="hero-section">
    <div id="hero-background-container"></div>
    <div class="container">
        <div class="row">
            <div id="hero-text-container" class="col-md-12">
                <h3 class="additional-headline">Anschnallen, bitte.</h3>
                <h1 id="hero-headline">Highspeed für deine Kfz-Gutachten</h1>
                <p id="hero-tagline">
                    Egal ob im Büro oder im Home Office, mit autoiXpert schreibst du deine Gutachten schnell & flexibel
                    von überall.
                    <br class="d-none d-sm-inline" />
                    Lass dich von der klar designten Oberfläche durch den Gutachtenprozess führen, der dir durch die
                    vielen 1-Klick-Exporte
                    <br class="d-none d-sm-inline" />
                    zu Audatex/DAT, Restwertbörsen und anderen eine Menge manuellen Aufwand spart.
                </p>
            </div>
        </div>
    </div>
    <img id="hero-curve-white" src="/assets/images/curve-white-small.svg" alt="" />
</section>
<!--============================================-->
<!-- END Hero -->
<!--============================================-->

<!--********** Calculation Providers **********-->
<section id="calculation-providers-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="order-1 order-lg-0 col-md-8 col-lg-6 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/kalkulationsanbieter.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/kalkulationsanbieter_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Audatex, DAT & GT Motive</h3>
                        <h2 class="feature-title">
                            Jeder Kalkulationsanbieter.
                            <br class="d-none d-lg-block" />
                            Alle Daten.
                        </h2>

                        <p class="feature-description">
                            AutoiXpert bietet zu jedem der großen Kalkulationsanbieter Audatex, DAT und GT Motive eine
                            Online-Schnittstelle.
                            <span
                                class="help-indicator fancy-tooltip"
                                title="Exportiere die Fahrzeugdaten und deine in autoiXpert hinterlegten Werkstattkostensätze mit einem Klick und importiere die Ergebnisse und Dokumente ebenso einfach.">
                                ?
                            </span>
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">So einfach geht's:</span>
                            Wähle den Anbieter, selektiere die erforderlichen Teile und lass alles wieder importieren -
                            voll automatisch.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="interfaces-row row justify-content-center">
            <!-- Spacer to align with row above -->
            <!--            <div class="col-sm-1 d-none d-xl-block"></div>-->

            <!--********** Audatex **********-->
            <div class="col-sm-8 col-lg-4 mb-3">
                <div class="interface-container">
                    <div id="audatex-interface-logo-container" class="interface-logo-container">
                        <img src="/assets/images/logos/audatex.svg" alt="Audatex-Logo" />
                    </div>
                    <div class="interface-text-container">
                        <div>Kalkulation & VIN</div>
                        <div>Bewertung</div>
                        <div>Mietwagenspiegel</div>
                    </div>
                </div>
            </div>

            <!--********** DAT **********-->
            <div class="col-sm-8 col-lg-4 mb-3">
                <div class="interface-container">
                    <div id="dat-interface-logo-container" class="interface-logo-container">
                        <img src="/assets/images/logos/dat-logo-no-text.svg" alt="DAT-Logo" />
                    </div>
                    <div class="interface-text-container">
                        <div>Kalkulation & VIN</div>
                        <div>Bewertung</div>
                        <div>Mietwagenspiegel</div>
                    </div>
                </div>
            </div>

            <!--********** GT Motive **********-->
            <div class="col-sm-8 col-lg-4 mb-3">
                <div class="interface-container">
                    <div id="gtmotive-interface-logo-container" class="interface-logo-container">
                        <img src="/assets/images/logos/gtmotive-logo.png" alt="DAT-Logo" />
                    </div>
                    <div class="interface-text-container">
                        <div>Kalkulation & VIN</div>
                    </div>
                </div>
            </div>

            <!-- Spacer to align with row above -->
            <!--            <div class="col-sm-1 d-none d-xl-block"></div>-->
        </div>
    </div>
</section>

<!--********** Car Registration Scanner **********-->
<section id="car-registration-scanner-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Fahrzeugschein auslesen</h3>
                        <h2 class="feature-title">Abtippen adé: Der neue Fahrzeugschein-Scanner</h2>

                        <p class="feature-description">
                            Lies jetzt die Halterdaten, die VIN & mehr aus - mit einzigartiger Genauigkeit. Nicht ganz
                            sicher, ob alles richtig erkannt wurde? Fahre mit der Maus ganz einfach über die Großansicht
                            des Fotos, um die erkannten Werte abzugleichen und ggf. anzupassen.
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">Foto hochladen & los!</span>
                            In nur 2 Sekunden liest du die Daten aus, die du bisher von Hand abgetippt hast. Scanne den
                            Schein einfach mit der Tablet-Kamera oder lade ein Foto am Computer hoch.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-lg-6 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/fahrzeugscheinscanner.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/fahrzeugscheinscanner_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Interfaces **********-->
<section id="interfaces-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="order-1 order-lg-0 col-md-8 col-lg-6 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/restwertboersen.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/restwertboersen_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Markt- & Restwerte</h3>
                        <h2 class="feature-title">
                            Weniger Doppeleingaben.
                            <br class="d-none d-lg-block" />
                            Mehr Zeit.
                        </h2>

                        <p class="feature-description">
                            Gibst du dieselben Fahrzeugdaten in verschiedene Systeme ein? Das übernimmt nun autoiXpert
                            für dich.
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">Die Verbindung ist einfach</span>
                            Gib einfach die Zugangsdaten zu deinen Accounts auf den Partner-Plattformen in den
                            autoiXpert-Einstellungen ein und lege sofort los.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!--********** Interface Logos **********-->
        <div id="market-and-residual-value-logos-row" class="row justify-content-center">
            <!--********** CARTV **********-->
            <div class="col text-center">
                <img
                    id="cartv-residual-value-exchange-logo"
                    class="market-and-residual-value-logo fancy-tooltip"
                    title="Analyse zum Wiederbeschaffungswert (Marktwert) und Ermittlung des Restwerts"
                    src="/assets/images/logos/cartv-logo.png"
                    alt="Logo CARTV Restwerte & Marktwerte" />
            </div>

            <!--********** AUTOonline **********-->
            <div class="col text-center">
                <img
                    class="market-and-residual-value-logo fancy-tooltip"
                    title="Ermittlung des Restwerts"
                    src="/assets/images/logos/autoonline-logo.svg"
                    alt="Logo AUTOonline" />
            </div>

            <!--********** VALUEpilot **********-->
            <div class="col text-center">
                <img
                    class="market-and-residual-value-logo fancy-tooltip"
                    title="Analyse zum Wiederbeschaffungswert (Marktwert)"
                    src="/assets/images/logos/valuepilot.png"
                    alt="Logo VALUEpilot" />
            </div>

            <!--********** Winvalue **********-->
            <div class="col text-center">
                <img
                    class="market-and-residual-value-logo fancy-tooltip"
                    title="Analyse zum Wiederbeschaffungswert (Marktwert) und Ermittlung des Restwerts"
                    src="/assets/images/logos/winvalue-logo.png"
                    alt="Logo Winvalue" />
            </div>

            <!--********** NetCasion **********-->
            <div class="col text-center">
                <img
                    class="market-and-residual-value-logo fancy-tooltip"
                    title="Ermittlung des Restwerts"
                    src="/assets/images/logos/netcasion-logo.png"
                    alt="Logo net.casion" />
            </div>
        </div>
    </div>
</section>

<!--********** Learning Software **********-->
<section id="learning-software-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Lernende Software</h3>
                        <h2 class="feature-title">Beteiligte automatisch merken</h2>

                        <p class="feature-description">
                            Je länger du autoiXpert nutzt, desto besser passt es sich an deine Arbeit an. Die Software
                            schlägt dir automatisch vergangene Kunden, Werkstätten, Rechtsanwälte und vieles mehr vor.
                            <br />
                            <br />
                            <strong>Kontaktimporte:</strong>
                            neXtsoft, easyExpert, AudaFusion, UltraExpert, Dynarex & mehr.
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">
                                Lange in den Einstellungen suchen? Fehlanzeige!
                            </span>
                            Vieles merkt sich autoiXpert sofort, wenn du es im Laufe eines Gutachtens einstellst. Dazu
                            gehören z. B. Foto-Ansichten, Rechnungs- positionen oder die bei dir übliche
                            Gültigkeitsdauer von Restwertgeboten.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-lg-6 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/lernende-software.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/lernende-software_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Photo Editor **********-->
<section id="photo-editor-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-6 order-1 order-lg-0 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/fotos.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/fotos_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Mühelos Fotos bearbeiten</h3>
                        <h2 class="feature-title">Manuell Fotos verkleinern? Fehlanzeige.</h2>

                        <p class="feature-description">
                            Lass die Auflösung deiner Fotos automatisch vor dem Hochladen optimieren. Das spart dir
                            Arbeit, beschleunigt den Upload und bewahrt gleichzeitig maximale Detailtiefe.
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">Features für die Foto-Bearbeitung</span>
                            Fotoversionen für Gutachten & Restwertbörse sind getrennt bearbeitbar • Verschiedene Filter
                            helfen dir, nicht optimale Fotos trotzdem verwenden zu können • Foto-Upload vom Computer
                            oder Tablet.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Writing Work **********-->
<section id="writing-work-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Mehr Zeit für deine Leidenschaft: Autos.</h3>
                        <h2 class="feature-title">Automatische Auswahl der Textbausteine</h2>

                        <p class="feature-description">
                            Überlasse die Schreibarbeit der Software. autoiXpert wählt alle Textbausteine automatisch
                            anhand deiner Eingaben aus, z. B. ob ein Minderwert vorhanden ist.
                            <br class="d-none d-lg-block" />
                            Die Auswahlregeln & Inhalte kannst du nach Belieben anpassen.
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">Mehr Textbaustein-Power</span>
                            Professionelle Textbausteine sind im Standard enthalten, alle zu 100&nbsp;%
                            individualisierbar. Export als Word-Datei möglich, um exotischen Sonderfällen den letzten
                            Schliff zu geben, auch hinsichtlich des Layouts.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-lg-6 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/schreibarbeit.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/schreibarbeit_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Professional Layout **********-->
<section id="professional-layout-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-6 order-1 order-lg-0 feature-image-column">
                <div id="professional-layout-feature-image" class="feature-image-container">
                    <a
                        class="fancy-tooltip"
                        href="/assets/downloads/gutachten-blau-modern.pdf"
                        target="_blank"
                        rel="noopener"
                        title="Vorlage modern">
                        <img
                            class="feature-preview"
                            src="/assets/images/screenshots/report-preview-blue.png"
                            alt="Professionelles Gutachten für Kfz-Sachverständige in blau" />
                    </a>
                    <a
                        class="fancy-tooltip"
                        href="/assets/downloads/gutachten-orange.pdf"
                        target="_blank"
                        rel="noopener"
                        title="Vorlage orange mit eigenem Logo, Foto auf dem Deckblatt sowie eigener Akzentfarbe & eigener Kopf- & Fußzeile">
                        <img
                            class="feature-preview"
                            src="/assets/images/screenshots/report-preview-orange.png"
                            alt="Professionelles Gutachten für Kfz-Gutachter in orange" />
                    </a>
                </div>
            </div>
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Professionelle Vorlagen</h3>
                        <h2 class="feature-title">Außenwirkung, für die du Komplimente bekommst</h2>

                        <p class="feature-description">
                            Beeindrucke deine Kunden mit professionell designten und verständlich strukturierten
                            Gutachten.
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">Was ist konfigurierbar?</span>
                            Kopf- & Fußzeile, Farben, Texte, Schriftart, Deckblatt. Briefpapier ist
                            Multi-Standort-fähig.
                            <span
                                class="help-indicator fancy-tooltip"
                                title="Für jeden Standort lassen sich eigene Briefpapiere hinterlegen.">
                                ?
                            </span>
                            <br class="d-none d-lg-block" />
                            Vorlagen-Beispiele:
                            <a href="/assets/downloads/gutachten-blau-modern.pdf" target="_blank" rel="noopener">
                                modern
                            </a>
                            •
                            <a href="/assets/downloads/gutachten-blau-klassisch.pdf" target="_blank" rel="noopener">
                                klassisch
                            </a>
                            •
                            <a href="/assets/downloads/gutachten-orange.pdf" target="_blank" rel="noopener">
                                individuelles Layout
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Invoices & Payment Reminders **********-->
<section id="invoices-and-payment-reminders-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Money makes the world go 'round</h3>
                        <h2 class="feature-title">Rechnungswesen & Mahnläufe</h2>

                        <p class="feature-description">
                            Generiere Rechnungen auf Knopfdruck aus einem Vorgang und verfolge stets den aktuellen
                            Mahnstatus.
                        </p>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">Freund & Helfer der Buchhaltung</span>
                            Automatische Honorarvorschläge auf Basis der aktuellen Vorgaben der Verbände (BVSK, VKS,
                            ...) oder eigener Honorartabellen • Zusatzdokumente (z. B. Aufforderung Überweisung MwSt.
                            Unternehmen) • Zahlungserfassung • individuelle Rechnungen
                            <span
                                class="help-indicator fancy-tooltip"
                                title="Falls du Rechnungen außerhalb der Standardprozesse schreiben möchtest, kannst du das auch ganz einfach in autoiXpert tun. Mithilfe anpassbarer Vorlagen, fortlaufender Rechnungsnummer und in genau deinem Layout.">
                                ?
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-lg-6 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/rechnungswesen.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/rechnungswesen_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Work Whereever **********-->
<section id="work-whereever-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-6 order-1 order-lg-0 feature-image-column">
                <div class="feature-image-container">
                    <img
                        class="feature-preview"
                        src="/assets/images/couch-blue.jpg"
                        alt="Kfz-Sachverständige & Gutachter arbeiten von dort, wo sie wollen" />
                </div>
            </div>
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Orts- & geräteunabhängig</h3>
                        <h2 class="feature-title">Arbeite, wann und wo du willst</h2>
                        <p class="feature-description">
                            Egal ob im Büro, im Home Office oder auf der Couch: Arbeite, wie und wo es dir passt.
                        </p>
                        <div id="work-whereever-os-and-browser-icons">
                            <img
                                src="/assets/images/logos/windows.png"
                                alt="Windows Icon"
                                class="fancy-tooltip"
                                title="autoiXpert läuft auf Windows in modernen Browsern" />
                            <img
                                src="/assets/images/logos/apple.png"
                                alt="Apple Icon"
                                class="fancy-tooltip"
                                title="autoiXpert läuft auf MacOS & iOS in modernen Browsern" />
                            <hr />
                            <img
                                src="/assets/images/logos/chrome.png"
                                alt="Chrome Icon"
                                class="fancy-tooltip"
                                title="autoiXpert läuft in jedem modernen Browser, z. B. Google Chrome" />
                            <img
                                src="/assets/images/logos/firefox.png"
                                alt="Firefox Icon"
                                class="fancy-tooltip"
                                title="autoiXpert läuft in jedem modernen Browser, z. B. Mozilla Firefox" />
                            <img
                                src="/assets/images/logos/safari.png"
                                alt="Safari Icon"
                                class="fancy-tooltip"
                                title="autoiXpert läuft in jedem modernen Browser, z. B. Apple Safari" />
                        </div>
                    </div>
                    <div class="feature-side-text-container">
                        <p>
                            <span class="feature-side-text-headline">Pro-Tipp:</span>
                            Starte früher in den Feierabend, um Zeit mit deiner Familie zu verbringen. Wenn die Kinder
                            im Bett sind, kannst du mit autoiXpert noch ein paar Gutachten erledigen. Auch auf deinem
                            Mac, iPad oder Android-Tablet.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--********** Useful Shortcuts **********-->
<section id="useful-shortcuts-section" class="feature-section">
    <div class="container">
        <div class="row align-items-center justify-content-around">
            <div class="col-md-8 col-lg-5">
                <div class="feature-description-container">
                    <div class="feature-main-text-container">
                        <h3 class="additional-headline">Nützliche Shortcuts</h3>
                        <h2 class="feature-title">Kleine Dinge, die das Leben leichter machen</h2>

                        <p class="feature-description">
                            An vielen Stellen spart dir autoiXpert durch nützliche Kleinigkeiten etwas Zeit, die in
                            Summe zu viel Freiraum führen.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-lg-6 feature-image-column">
                <div class="feature-image-container screencast">
                    <a href="/assets/videos/kleine-dinge.mp4" target="_blank" rel="noopener">
                        <video
                            class="feature-preview"
                            src="/assets/videos/kleine-dinge_small.mp4"
                            muted
                            loop
                            playsinline></video>
                    </a>
                </div>
            </div>
        </div>
        <div id="useful-shortcuts-icons-row" class="row mb-md-4">
            <!--********** Spacer **********-->
            <div class="col-sm-1"></div>
            <div class="col-sm-5 mb-3 mb-md-0">
                <div class="useful-shortcuts-container">
                    <div class="useful-shortcuts-icon-container">
                        <img src="/assets/images/icons/report-token.png" alt="Gutachten-Aktenzeichen" />
                    </div>
                    <div class="useful-shortcuts-text-container">
                        <h4 class="useful-shortcuts-title">Aktenzeichen</h4>
                        <div class="useful-shortcuts-description">
                            Vielfältige Einstellungen mit Platzhaltern, darunter Tag, Monat, Jahr, Mitarbeiterkürzel und
                            viele weitere.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 mb-3 mb-md-0">
                <div class="useful-shortcuts-container">
                    <div class="useful-shortcuts-icon-container">
                        <img src="/assets/images/icons/calendar.png" alt="Datumsfeld für Besichtigungen" />
                    </div>
                    <div class="useful-shortcuts-text-container">
                        <h4 class="useful-shortcuts-title">Datumsfeld</h4>
                        <div class="useful-shortcuts-description">
                            Tippe z. B. “g” für das Datum von gestern oder “v” für vorgestern. Es gibt viele weitere
                            Kürzel.
                        </div>
                    </div>
                </div>
            </div>
            <!--********** Spacer **********-->
            <div class="col-sm-1"></div>
        </div>
        <div class="row mb-md-4">
            <!--********** Spacer **********-->
            <div class="col-sm-1"></div>
            <div class="col-sm-5 mb-3 mb-md-0">
                <div class="useful-shortcuts-container">
                    <div class="useful-shortcuts-icon-container">
                        <img src="/assets/images/icons/search.png" alt="Gutachten suchen & finden" />
                    </div>
                    <div class="useful-shortcuts-text-container">
                        <h4 class="useful-shortcuts-title">Universal-Suchfeld</h4>
                        <div class="useful-shortcuts-description">
                            Finde das richtige Gutachten sofort, z. B. wenn ein Kunde anruft.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 mb-3 mb-md-0">
                <div class="useful-shortcuts-container">
                    <div class="useful-shortcuts-icon-container">
                        <img src="/assets/images/icons/euro-small.png" alt="Zahlungen für Gutachten erfassen" />
                    </div>
                    <div class="useful-shortcuts-text-container">
                        <h4 class="useful-shortcuts-title">Währungsfelder</h4>
                        <div class="useful-shortcuts-description">
                            Gib z. B. “50 %” ein und autoiXpert berechnet die Hälfte einer Zahlung, etwa bei einer
                            Teilschuld.
                        </div>
                    </div>
                </div>
            </div>
            <!--********** Spacer **********-->
            <div class="col-sm-1"></div>
        </div>
    </div>
</section>

<ax-exclamation-box></ax-exclamation-box>

<!--********** Your Data in Good Hands **********-->
<section id="data-security-section" class="feature-section">
    <div class="container text-center">
        <div class="row">
            <div class="col-sm">
                <h3 class="additional-headline">Datensicherheit</h3>
                <h2>Deine Daten in guten Händen</h2>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-6">
                Deine Daten und die deiner Kunden werden stets verschlüsselt übertragen und ausschließlich in
                Deutschland gespeichert.
            </div>
        </div>

        <div class="row justify-content-center" id="data-security-row">
            <div class="col-md-8 col-lg-6 feature-grid-item-container mb-5 mb-lg-0">
                <div class="feature-grid-item">
                    <div class="feature-icon-container">
                        <img src="/assets/images/icons/lock.png" alt="Icon Verschlüsselung der Gutachten" />
                    </div>
                    <h4 class="feature-name">Erweiterte Datensicherheit</h4>
                    <div class="feature-description">
                        Deine Daten und die deiner Kunden sind bei uns sicher.
                        <br class="d-none d-lg-block" />
                        Unsere hochmodernen Rechenzentren sind nach DIN
                        <br class="d-none d-lg-block" />
                        ISO 27001 und durch das Bundesamt für Sicherheit in
                        <br class="d-none d-lg-block" />
                        der Informationstechnik (BSI) nach dem aktuellen
                        <br class="d-none d-lg-block" />
                        Prüfschema C5 zertifiziert.
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-lg-6 feature-grid-item-container">
                <div class="feature-grid-item">
                    <div class="feature-icon-container">
                        <img src="/assets/images/icons/deutschland-umriss.png" alt="Icon Umriss Deutschland" />
                    </div>
                    <h4 class="feature-name">Daten in Deutschland</h4>
                    <div class="feature-description">
                        Unsere Rechenzentren befinden sich in Frankfurt a. M.
                        <br class="d-none d-lg-block" />
                        und erfüllen die Richtlinien deutscher und europäischer
                        <br class="d-none d-lg-block" />
                        Datenschutzgesetze. Der Datenaustausch erfolgt zu jeder
                        <br class="d-none d-lg-block" />
                        Zeit über eine Hochsicherheitsverbindung mit
                        <br class="d-none d-lg-block" />
                        RSA-2048-Bit-Verschlüsselung.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="Funktionen" class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div id="feature-section-intro-container">
                    <h3 class="additional-headline">Für den Kfz-Sachverständigen von Welt</h3>
                    <h2 id="feature-section-intro-heading">Funktionen im Überblick</h2>
                    <p id="feature-section-intro-text">
                        autoiXpert wird dich begeistern. Die modernste Software für KFZ-Sachverständige
                        <br class="d-none d-lg-block" />
                        auf dem Markt ist voll mit genialen Funktionen und nützlichen Shortcuts,
                        <br class="d-none d-lg-block" />
                        die deine Arbeit jeden Tag leichter machen.
                        <br class="d-none d-lg-block" />
                    </p>
                </div>
            </div>
        </div>
        <div id="feature-grid-length-limiter" [class.expanded]="featuresGridExpanded">
            <div class="row" data-row="1">
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">description</i>
                        </div>
                        <h4 class="feature-name">Schneller zum Gutachten</h4>
                        <div class="feature-description">
                            Von Datenaufnahme bis zum
                            <br class="d-none d-lg-block" />
                            fertigen Gutachten schneller als je zuvor
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">search</i>
                        </div>
                        <h4 class="feature-name">VIN-Abfrage</h4>
                        <div class="feature-description">
                            Vollständige Fahrzeugdaten
                            <br class="d-none d-lg-block" />
                            mit einem Klick abfragen
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">directions_car</i>
                        </div>
                        <h4 class="feature-name">Schadenkalkulation</h4>
                        <div class="feature-description">
                            Kalkuliere mit SilverDAT 3,
                            <br class="d-none d-lg-block" />
                            Audatex, GT Motive oder manuell
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" data-row="2">
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">gavel</i>
                        </div>
                        <h4 class="feature-name">Restwertbörsen</h4>
                        <div class="feature-description">
                            Schnittstellen zu CARTV
                            <br class="d-none d-lg-block" />
                            car.casion & WinValue
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">timeline</i>
                        </div>
                        <h4 class="feature-name">Marktwertanalyse</h4>
                        <div class="feature-description">
                            1-Klick-Schnittstellen
                            <br class="d-none d-lg-block" />
                            zu CARTV und WinValue
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">bar_chart</i>
                        </div>
                        <h4 class="feature-name">Mietwagenpreise</h4>
                        <div class="feature-description">
                            Automatisch über den DAT-
                            <br class="d-none d-lg-block" />
                            Mietwagenspiegel berechnen lassen
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" data-row="3">
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">library_books</i>
                        </div>
                        <h4 class="feature-name">Professionelle Vorlagen</h4>
                        <div class="feature-description">
                            Wähle aus verschiedenen von
                            <br class="d-none d-lg-block" />
                            unseren Designern entworfenen
                            <br class="d-none d-lg-block" />
                            Gutachten-Vorlagen
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">palette</i>
                        </div>
                        <h4 class="feature-name">Individuelles Briefpapier</h4>
                        <div class="feature-description">
                            Gutachten und Rechnung mit eigenem
                            <br class="d-none d-lg-block" />
                            Briefkopf- und -fuß individualisieren.
                            <br class="d-none d-lg-block" />
                            Jederzeit eigenständig anpassbar.
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">photo_camera</i>
                        </div>
                        <h4 class="feature-name">Foto-Editor</h4>
                        <div class="feature-description">
                            Schäden durch Freitexte &
                            <br class="d-none d-lg-block" />
                            verschiedenfarbige Formen direkt
                            <br class="d-none d-lg-block" />
                            auf dem Foto dokumentieren
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" data-row="4" id="feature-grid-invoice-row">
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <img src="/assets/images/icon-invoice.svg" alt="" class="custom-material-icon" />
                        </div>
                        <h4 class="feature-name">Rechnung erstellen</h4>
                        <div class="feature-description">
                            Auf Knopfdruck Rechnungen
                            <br class="d-none d-lg-block" />
                            zum Gutachten schreiben lassen
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <img src="/assets/images/icon-euro-symbol-filled.svg" alt="" class="custom-material-icon" />
                        </div>
                        <h4 class="feature-name">Rechnungswesen</h4>
                        <div class="feature-description">
                            Umsätze, Zahlungsstatus
                            <br class="d-none d-lg-block" />
                            und offene Posten managen
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">euro_symbol</i>
                        </div>
                        <h4 class="feature-name">Honorar berechnen</h4>
                        <div class="feature-description">
                            Über Tabellen von BVSK, HUK, VKS
                            <br class="d-none d-lg-block" />
                            oder eigene das ideale Honorar ermitteln
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" data-row="5" id="feature-grid-online-advantages-row">
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">send</i>
                        </div>
                        <h4 class="feature-name">Gutachten per E-Mail</h4>
                        <div class="feature-description">
                            Mit wenigen Klicks eine PDF an
                            <br class="d-none d-lg-block" />
                            Versicherung, Anspruchsteller
                            <br class="d-none d-lg-block" />
                            und weitere senden
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">people</i>
                        </div>
                        <h4 class="feature-name">Im Team arbeiten</h4>
                        <div class="feature-description">
                            Gutachten einfach zwischen
                            <br class="d-none d-lg-block" />
                            Kollegen austauschen
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">public</i>
                        </div>
                        <h4 class="feature-name">Überall erreichbar</h4>
                        <div class="feature-description">
                            Auch unterwegs oder im Home Office
                            <br class="d-none d-lg-block" />
                            auf alle Daten zugreifen
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">cached</i>
                        </div>
                        <h4 class="feature-name">Immer neueste Version</h4>
                        <div class="feature-description">
                            Updates werden automatisch und
                            <br class="d-none d-lg-block" />
                            kostenfrei bereitgestellt
                        </div>
                    </div>
                </div>
                <!--<div class="col-md-4 feature-grid-item-container">-->
                <!--<div class="feature-grid-item">-->
                <!--<div class="feature-icon-container">-->
                <!--<i class="material-icons">signal_wifi_off</i>-->
                <!--</div>-->
                <!--<h4 class="feature-name">Offline arbeiten</h4>-->
                <!--<div class="feature-description">-->
                <!--Auch ohne Netz arbeiten und im<br class="d-none d-lg-block">-->
                <!--Büro automatisch synchronisieren lassen-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">tablet_mac</i>
                        </div>
                        <h4 class="feature-name">Tablet-ready</h4>
                        <div class="feature-description">
                            Direkt vor Ort Daten zu Unfall
                            <br class="d-none d-lg-block" />
                            & Beteiligten aufnehmen
                        </div>
                    </div>
                </div>
                <div class="col-md-4 feature-grid-item-container">
                    <div class="feature-grid-item">
                        <div class="feature-icon-container">
                            <i class="material-icons">credit_card</i>
                        </div>
                        <h4 class="feature-name">Forderungen sofort bezahlt</h4>
                        <div class="feature-description">
                            Forderungen an Factoring-Anbieter
                            <br class="d-none d-lg-block" />
                            abtreten und sofort auszahlen lassen
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--********** Mobile only **********-->
        <div class="row" id="features-toggle-container" [class.expanded]="featuresGridExpanded">
            <div class="col-12 text-center">
                <a
                    href="javascript:void(0)"
                    id="more-features-toggle"
                    class="features-toggle"
                    (click)="featuresGridExpanded = true">
                    Mehr Funktionen anzeigen
                </a>
                <a
                    href="javascript:void(0)"
                    id="less-features-toggle"
                    class="features-toggle"
                    (click)="featuresGridExpanded = false">
                    Weniger Funktionen anzeigen
                </a>
            </div>
        </div>
    </div>
</section>

<!--============================================-->
<!-- Youtube Channel -->
<!--============================================-->
<section id="youtube-channel-section" class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div id="youtube-channel-container" class="exclamation-box-container" (click)="openYoutubeVideo()">
                    <!--============================================-->
                    <!-- Text -->
                    <!--============================================-->
                    <div id="youtube-channel-text-container">
                        <h3 class="additional-headline">autoiXpert auf YouTube</h3>
                        <h2 class="exclamation-box-heading">
                            Der Channel für
                            <br />
                            Gutachter & Sachverständige
                        </h2>
                        <div id="youtube-channel-buttons-container">
                            <!--********** Video Button **********-->
                            <div id="open-youtube-channel-button">
                                <img src="/assets/images/video-play-button.svg" alt="Play Icon" />
                                Zu den Videos
                            </div>

                            <!--********** YouTube Logo **********-->
                            <img
                                id="youtube-logo-in-channel-container"
                                src="/assets/images/logos/youtube-logo-light.png"
                                alt="YouTube Logo" />
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Text -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Video Screenshots -->
                    <!--============================================-->
                    <div id="youtube-channel-thumbnails">
                        <img
                            src="/assets/images/screenshots/fahrzeugscheinscanner-video.jpg"
                            alt="Video-Thumbnail Fahrzeugscheinscanner für Kfz-Gutachter" />
                        <img
                            src="/assets/images/screenshots/start-als-sachverstaendiger-video.jpg"
                            alt="Video-Thumbnail Start als Kfz-Sachverständiger" />
                        <img
                            src="/assets/images/screenshots/restwertverteiler-video.jpg"
                            alt="Video-Thumbnail Restwertverteiler" />
                    </div>
                    <!--============================================-->
                    <!-- END Video Screenshots -->
                    <!--============================================-->
                </div>
            </div>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Youtube Channel -->
<!--============================================-->
