import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

/**
 * Stolen from project ngx-json-ld (https://github.com/coryrylan/ngx-json-ld/blob/master/lib/ngx-json-ld.component.ts)
 */
@Component({
    selector: 'ax-google-structured-data',
    template: '',
    standalone: true,
})
export class GoogleStructuredDataComponent implements OnChanges {
    @Input() json: Record<string, any> = {};
    @HostBinding('innerHTML') jsonLD: SafeHtml = '';

    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges) {
        this.jsonLD = this.getSafeHTML(changes['json'].currentValue);
    }

    getSafeHTML(value: Record<string, any> = {}) {
        const json = value ? JSON.stringify(value, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
        const html = `<script type="application/ld+json">${json}</script>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
