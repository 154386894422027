<section id="target-group-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm text-center">
                <h3 class="additional-headline">Zielgruppe</h3>
                <h2>Für wen ist das Webinar geeignet?</h2>
                <p>
                    <ng-content></ng-content>
                </p>
            </div>
        </div>
        <div class="row justify-content-center">
            <!--********** Beginners **********-->
            <div class="col-sm col-md-3 justify-content-center">
                <div class="target-group-container" [class.active]="targetGroupLevel.beginner">
                    <i class="material-icons target-group-active-icon">check</i>
                    Einsteiger
                </div>
            </div>

            <!--********** Intermediate **********-->
            <div class="col-sm col-md-3 justify-content-center">
                <div class="target-group-container" [class.active]="targetGroupLevel.intermediate">
                    <i class="material-icons target-group-active-icon">check</i>
                    Fortgeschrittene
                </div>
            </div>

            <!--********** Professionals **********-->
            <div class="col-sm col-md-3 justify-content-center">
                <div class="target-group-container" [class.active]="targetGroupLevel.professional">
                    <i class="material-icons target-group-active-icon">check</i>
                    Experten
                </div>
            </div>
        </div>
    </div>
</section>
