<section id="agenda-section">
    <div class="container text-center">
        <div id="agenda-intro-row" class="row justify-content-center">
            <div class="col-sm col-md-8">
                <h3 class="additional-headline">Wertvolles Fachwissen</h3>
                <h2>{{ webinarTitle }}</h2>
                <ng-content></ng-content>
            </div>
        </div>

        <div class="row justify-content-center mb-5">
            @for (content of webinarContents; track content.title) {
                <div class="col-md-4">
                    <div class="agenda-item-container">
                        <div class="agenda-item">
                            <div class="agenda-item-heading">
                                @if (content.link) {
                                    <a [href]="content.link" target="_blank" rel="noopener">{{ content.title }}</a>
                                } @else {
                                    {{ content.title }}
                                }
                            </div>
                            <div class="agenda-item-description">
                                {{ content.description }}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

        <div *ngIf="maximumNumberOfParticipants" class="row justify-content-center text-center">
            <div class="col-sm col-md-6">
                <!-- prettier-ignore -->
                <p>
                    @if (isOnlineExchange) {
                        Dieses Webinar ist <strong>auf {{ maximumNumberOfParticipants }} Teilnehmer begrenzt</strong>,
                            damit die Atmosphäre eine persönliche bleibt.
                    } @else {
                        Dieses Webinar ist
                        <strong>
                            auf {{ maximumNumberOfParticipants }} Teilnehmer
                            begrenzt</strong>, damit wir auf deine persönlichen Fragen eingehen können. Weitere
                            Webinare findest du auf unserer
                        <a href="/">Startseite</a>.
                    }
                </p>
            </div>
        </div>
    </div>
</section>
