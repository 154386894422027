// Slide animation for the calculation provider logos
import { animate, style, transition, trigger } from '@angular/animations';

export function moveOutLeft(leaveDuration = 800) {
    return trigger('moveOutLeft', [
        transition(
            ':leave',
            animate(
                `${leaveDuration}ms ease`,
                style({
                    transform: 'translate3d(-100vw, 0, 0)',
                    opacity: 0,
                }),
            ),
        ),
    ]);
}
