<section id="pricing-intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-6">
                <h3 class="additional-headline">Preise für Bürosoftware und Kalkulation</h3>
                <h1 id="pricing-intro-headline">Was kostet Software für Kfz-Sachverständige?</h1>
                <p id="pricing-intro-tagline">
                    Nur wenn der Preis klar ist, kann man eine Entscheidung für die optimale Software treffen. Wir
                    helfen dir dabei, indem wir komplexe Preismodelle unserer Partner einfach machen.
                </p>
            </div>
        </div>
    </div>
</section>

<div>
    <section id="calculator-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="input-card-container col-12 col-md-6 col-lg-8 col-xl-6">
                    <div id="input-card" class="frosted-glass-card">
                        <h2 class="h2-ax-app">Deine Eingaben</h2>
                        <div id="number-of-reports-row" class="frosted-glass-card-row">
                            <div class="frosted-glass-card-label-container">
                                <h3 class="h3-ax-app">
                                    Gutachten
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Einsteiger schreiben meist zwischen 5-10 Gutachten im Monat. Erfahrene Gutachter üblicherweise ca. 25-35 Gutachten im Monat.">
                                        ?
                                    </span>
                                </h3>
                                <span class="label-ax-app">pro Monat</span>
                            </div>

                            <div id="reports-per-month-controls-and-hint-container">
                                <div class="reports-per-month-controls">
                                    <mat-slider
                                        class="reports-per-month-slider"
                                        min="1"
                                        max="30"
                                        step="1"
                                        [value]="numberOfReportsPerMonth()"
                                        (input)="reportsPerMonthSliderValueChanged($event)"
                                        thumbLabel></mat-slider>
                                    <mat-form-field class="mat-block">
                                        <input
                                            matInput
                                            class="number-input"
                                            type="number"
                                            min="1"
                                            step="1"
                                            (input)="numberOfReportsChanged()"
                                            [(ngModel)]="numberOfReportsPerMonth" />
                                    </mat-form-field>
                                </div>

                                <!--********** Hint: Use input for more reports **********-->
                                <div
                                    id="hint-how-to-enter-more-reports"
                                    class="label"
                                    *ngIf="numberOfReportsPerMonth() === 30"
                                    @slideInAndOutVertically>
                                    Mehr Gutachten? Gib die Anzahl im Feld neben dem Slider ein.
                                </div>
                            </div>
                        </div>

                        <div class="frosted-glass-card-row">
                            <div class="frosted-glass-card-label-container">
                                <h3 class="h3-ax-app">Nutzer</h3>
                            </div>

                            <div class="toggle-group">
                                <button
                                    class="toggle-group-button"
                                    tabindex="0"
                                    [class.selected]="numberOfUsers() === 1 && !showCustomNumberOfUsersInputField"
                                    (click)="numberOfUsersChanged(1)">
                                    <mat-icon>person</mat-icon>
                                    1
                                </button>
                                <button
                                    class="toggle-group-button"
                                    tabindex="0"
                                    [class.selected]="numberOfUsers() === 2 && !showCustomNumberOfUsersInputField"
                                    (click)="numberOfUsersChanged(2)">
                                    <mat-icon>group</mat-icon>
                                    2
                                </button>
                                <button
                                    class="toggle-group-button"
                                    tabindex="0"
                                    [class.selected]="numberOfUsers() === 3 && !showCustomNumberOfUsersInputField"
                                    (click)="numberOfUsersChanged(3)">
                                    <mat-icon>groups</mat-icon>
                                    3
                                </button>
                                <div class="toggle-group-button-with-input-container">
                                    <button
                                        class="toggle-group-button"
                                        (click)="customNumberOfUsersSelected()"
                                        [tabIndex]="showCustomNumberOfUsersInputField ? -1 : 0">
                                        <mat-icon>groups</mat-icon>
                                        +
                                    </button>
                                    @if (showCustomNumberOfUsersInputField) {
                                        <mat-form-field class="mat-block">
                                            <input
                                                #customNumberOfUsersInput
                                                matInput
                                                class="number-input"
                                                type="number"
                                                [(ngModel)]="numberOfUsers" />
                                        </mat-form-field>
                                    }
                                </div>
                            </div>
                        </div>

                        <div class="frosted-glass-card-row">
                            <div class="frosted-glass-card-label-container">
                                <h3 class="h3-ax-app">Kalkulation</h3>
                            </div>

                            <div class="toggle-group" id="calculation-provider-toggle-group">
                                <button
                                    class="toggle-group-button"
                                    [class.selected]="calculationProvider() === 'audatex'"
                                    (click)="calculationProviderChanged('audatex')">
                                    <img src="/assets/images/logos/qapter-logo-small.png" alt="Audatex Qapter Logo" />
                                    Audatex
                                </button>
                                <button
                                    class="toggle-group-button"
                                    [class.selected]="calculationProvider() === 'dat'"
                                    (click)="calculationProviderChanged('dat')">
                                    <img src="/assets/images/logos/dat-logo.png" alt="DAT Logo" />
                                    DAT
                                </button>
                                <button
                                    class="toggle-group-button"
                                    [class.selected]="calculationProvider() === 'both'"
                                    (click)="calculationProviderChanged('both')">
                                    <mat-icon>looks_two</mat-icon>
                                    Beide
                                </button>
                            </div>
                        </div>

                        @if (calculationProvider() === 'both' && !hideCalculationProviderCostsInfoNote) {
                            <div id="frosted-glass-card-row-info-note" class="frosted-glass-card-row">
                                <div class="frosted-glass-card-label-container"></div>
                                <div class="info-note warning-note small-note" @fadeInAndOut>
                                    Sicher, dass du zwei Anbieter verwenden möchtest? Das ist recht teuer.
                                    <mat-icon
                                        class="info-note-close-icon"
                                        (click)="hideCalculationProviderCostsInfoNote = true">
                                        close
                                    </mat-icon>
                                </div>
                            </div>
                        }

                        @if (calculationProvider() === 'both') {
                            <div
                                id="frosted-glass-card-row-calculation-provider-distribution"
                                class="frosted-glass-card-row">
                                <div class="frosted-glass-card-label-container">
                                    <h4 class="h4-ax-app">Verteilung</h4>
                                </div>
                                <div class="calculation-provider-distribution-slider-container">
                                    <img src="/assets/images/logos/qapter-logo-small.png" alt="Qapter Logo" />
                                    <span class="label-ax-app">{{ numberOfReportsPerMonthAudatex() }} Stück</span>
                                    <mat-slider
                                        class="calculation-provider-distribution-slider"
                                        min="0"
                                        [max]="numberOfReportsPerMonth()"
                                        step="1"
                                        [value]="numberOfReportsPerMonthAudatex()"
                                        (input)="reportsPerMonthAudatexSliderValueChanged($event)"></mat-slider>
                                    <span class="label-ax-app">
                                        {{ numberOfReportsPerMonth() - numberOfReportsPerMonthAudatex() }} Stück
                                    </span>
                                    <img src="/assets/images/logos/dat-logo.png" alt="DAT Logo" />
                                </div>
                            </div>
                        }

                        <div id="market-and-residual-value-row" class="frosted-glass-card-row">
                            <div class="frosted-glass-card-label-container">
                                <h3 class="h3-ax-app">
                                    Markt- &
                                    <br class="break-market-and-residual-value-label" />
                                    Restwerte
                                    <span
                                        class="help-indicator fancy-tooltip"
                                        title="Annahmen:<br>
                                            <ul>
                                                <li>Preise (bei allen Anbietern ähnlich): 2,50 € pro Marktwertanalyse; 18 € pro Restwertinserat.</li>
                                                <li>ca. jedes dritte Gutachten erfordert einen Restwert.</li>
                                                <li>Plattformen für Markt- & Restwerte erheben keine Grundgebühren, sondern rechnen nur pro Fall ab.</li>
                                            </ul>
                                            <br>
                                            Restwertkosten können 1:1 an die Versicherung weitergereicht werden und sind damit für den Sachverständigen nur ein durchlaufender Posten.<br>
                                            <br>
                                            Alternativ kannst du Marktwerte auch über die DAT oder Audatex ermitteln.<br>
                                            Um Restwerte kostenfrei zu ermitteln, kannst du auch den lokalen Restwertverteiler von autoiXpert nutzen.">
                                        ?
                                    </span>
                                </h3>
                            </div>
                            <mat-slide-toggle
                                [(ngModel)]="includeMarketAndResidualValue"
                                [disableRipple]="true"
                                labelPosition="before"
                                (change)="includeMarketAndResidualValueChanged($event)">
                                Preise einbeziehen
                            </mat-slide-toggle>
                        </div>

                        <div id="scroll-to-prices-button" class="frosted-glass-card-row">
                            <button class="button-solid" (click)="scrollToResults()">
                                <mat-icon>expand_more</mat-icon>
                                Preise ansehen
                            </button>
                        </div>
                    </div>
                </div>

                <div #resultsSection class="col-12 col-md-6 col-lg-4">
                    <div id="result-card" class="frosted-glass-card">
                        <div class="frosted-glass-card-headline-container">
                            <h2 class="h2-ax-app">Preise</h2>
                            <span class="label-ax-app">pro Monat, netto</span>
                        </div>

                        <div class="frosted-glass-card-costs-item">
                            <div class="costs-item-row">
                                <img src="/assets/images/autoixpert-x.svg" alt="autoiXpert Logo" />
                                <div class="costs-row-labels">
                                    <span class="costs-row-bold-label">autoiXpert</span>
                                    <span class="label-ax-app">Bürosoftware</span>
                                </div>
                                <span class="costs-row-bold-label costs-row-price-label">
                                    {{ AUTOIXPERT_BASE_ACCOUNT_FEE }} €
                                </span>
                            </div>

                            @if (numberOfUsers() > 1) {
                                <div class="costs-item-row">
                                    <div class="frosted-glass-card-cost-item-image-placeholder"></div>
                                    <div class="costs-row-labels">
                                        <span class="costs-row-bold-label">
                                            {{ numberOfAdditionalUsers() > 1 ? numberOfAdditionalUsers() + ' ' : '' }}
                                            Zusatznutzer
                                        </span>
                                        <span class="label-ax-app">
                                            je {{ COST_PER_ADDITIONAL_USER | formatPrice: 0 }}
                                        </span>
                                    </div>
                                    <span class="costs-row-bold-label costs-row-price-label">
                                        {{ additionalUsersTotalCost() }} €
                                    </span>
                                </div>
                            }

                            @if (calculationProvider() === 'audatex' || calculationProvider() === 'both') {
                                <div class="costs-item-row">
                                    <div class="frosted-glass-card-cost-item-image-placeholder"></div>
                                    <div class="costs-row-labels">
                                        <span class="costs-row-bold-label">
                                            Audatex Addon
                                            <span
                                                class="help-indicator help-indicator-small fancy-tooltip"
                                                title="Freischaltung der Schnittstelle zwischen autoiXpert & Audatex. Ein separater Kalkulationszugang (Audatex Qapter, s. unten) ist weiterhin erforderlich.">
                                                ?
                                            </span>
                                        </span>
                                        <span class="label-ax-app">30 € + 10 € pro Zusatznutzer</span>
                                    </div>
                                    <span class="costs-row-bold-label costs-row-price-label">
                                        {{ audatexAddonTotalCost() | formatPrice: 0 }}
                                    </span>
                                </div>
                            }
                        </div>

                        @if (calculationProvider() === 'audatex' || calculationProvider() === 'both') {
                            <div
                                class="frosted-glass-card-costs-item show-open-in-new-tab-on-hover"
                                (click)="navigateTo('/Audatex-Preisrechner')">
                                <span class="costs-row-open-in-new-tab-button">
                                    <mat-icon class="toolbar-icon">open_in_new</mat-icon>
                                </span>
                                <div class="costs-item-row">
                                    <img src="/assets/images/logos/qapter-logo-small.png" alt="Qapter Logo" />
                                    <div class="costs-row-labels">
                                        <span class="costs-row-bold-label">Audatex Qapter</span>
                                        <span class="label-ax-app">Kfz-Daten + Kalkulation</span>
                                    </div>
                                    <span class="costs-row-bold-label costs-row-price-label">
                                        {{ audatexTotalCost() | formatPrice: 0 }}
                                    </span>
                                </div>
                            </div>
                        }

                        @if (calculationProvider() === 'dat' || calculationProvider() === 'both') {
                            <div
                                class="frosted-glass-card-costs-item show-open-in-new-tab-on-hover"
                                (click)="navigateTo('/DAT-Preisrechner')">
                                <span class="costs-row-open-in-new-tab-button">
                                    <mat-icon class="toolbar-icon">open_in_new</mat-icon>
                                </span>
                                <div class="costs-item-row">
                                    <img src="/assets/images/logos/dat-logo.png" alt="DAT Logo" />
                                    <div class="costs-row-labels">
                                        <span class="costs-row-bold-label">
                                            DAT SilverDAT 3
                                            <span
                                                class="help-indicator help-indicator-small fancy-tooltip"
                                                title="Die angezeigten monatlichen Kosten beinhalten einmalige Einrichtungsgebühren (z. B. Basislizenz und Einweisung). Die regelmäßigen monatlichen Kosten ab dem zweiten Jahr sind deutlich geringer. Diese findest du im DAT-Preisrechner. Klicke auf diese Kachel, um direkt dorthin zu gelangen.">
                                                ?
                                            </span>
                                        </span>
                                        <span class="label-ax-app">Kfz-Daten + Kalkulation</span>
                                    </div>
                                    <span class="costs-row-bold-label costs-row-price-label">
                                        {{ datTotalCost() | formatPrice: 0 }}
                                    </span>
                                </div>
                            </div>
                        }

                        @if (includeMarketAndResidualValue()) {
                            <div class="costs-item-divider-line"></div>

                            <div id="costs-item-sub-total" class="frosted-glass-card-costs-item">
                                <div class="costs-item-row">
                                    <mat-icon>functions</mat-icon>
                                    <div class="costs-row-labels">
                                        <span class="costs-row-bold-label">Zwischensumme</span>
                                        <span class="label-ax-app">ø pro Gutachten</span>
                                        <span class="label-ax-app" *ngIf="calculationProvider()">
                                            ø davon autoiXpert
                                        </span>
                                    </div>
                                    <div class="costs-row-values">
                                        <span class="costs-row-bold-label costs-row-price-label">
                                            {{ subTotal() | formatPrice: 0 }}
                                        </span>
                                        <span class="label-ax-app">{{ subTotalPerReport() | formatPrice }}</span>
                                        <span class="label-ax-app" *ngIf="calculationProvider()">
                                            {{ subTotalPerReportAutoixpert() | formatPrice }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="frosted-glass-card-costs-item">
                                <div class="costs-item-row">
                                    <mat-icon>gavel</mat-icon>
                                    <div class="costs-row-labels">
                                        <span class="costs-row-bold-label">
                                            Markt- & Restwerte
                                            <span
                                                #marketAndResidualValueTooltip
                                                class="help-indicator help-indicator-small fancy-tooltip"
                                                [title]="marketAndResidualValueRequestTooltip()">
                                                ?
                                            </span>
                                        </span>
                                        <span class="label-ax-app">AUTOonline, CARTV etc.</span>
                                    </div>
                                    <span class="costs-row-bold-label costs-row-price-label">
                                        {{ marketAndResidualValueCosts() | formatPrice: 0 }}
                                    </span>
                                </div>
                            </div>
                        }

                        @if (
                            calculationProvider() !== null ||
                            includeMarketAndResidualValue() ||
                            numberOfAdditionalUsers() > 0
                        ) {
                            <div class="costs-item-divider-line"></div>

                            <div id="costs-item-total" class="frosted-glass-card-costs-item">
                                <div class="costs-item-row">
                                    <mat-icon>functions</mat-icon>
                                    <div class="costs-row-labels">
                                        <span class="costs-row-bold-label">Summe</span>
                                        <span class="label-ax-app">
                                            ø
                                            {{
                                                includeMarketAndResidualValue()
                                                    ? 'inkl. Markt- & Restwerte'
                                                    : 'pro Gutachten'
                                            }}
                                        </span>
                                        <span
                                            *ngIf="!includeMarketAndResidualValue() && calculationProvider()"
                                            class="label-ax-app">
                                            ø davon autoiXpert
                                        </span>
                                    </div>
                                    <div class="costs-row-values">
                                        <span class="costs-row-bold-label costs-row-price-label">
                                            {{ totalCosts() | formatPrice: 0 }}
                                        </span>
                                        <span class="label-ax-app">{{ totalCostsPerReport() | formatPrice }}</span>
                                        <span
                                            *ngIf="!includeMarketAndResidualValue() && calculationProvider()"
                                            class="label-ax-app">
                                            {{ subTotalPerReportAutoixpert() | formatPrice }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="row justify-content-center disclaimer">
                <div class="col-md-6">
                    <p class="label-ax-app text-center">
                        Alle Preise netto.
                        <br />
                        Vertragslaufzeiten: monatlich bei autoiXpert, länger bei Audatex und DAT.
                        <br />
                        Weitere Details auf den allein gültigen Bestellformularen der jeweiligen Software-Anbieter.
                        Gewähr nur für unsere eigenen Preise, nicht die der Partner.
                    </p>
                </div>
            </div>
        </div>
    </section>
</div>

<ax-exclamation-box
    [headline]="'Noch Fragen?'"
    description="Wir helfen dir gerne persönlich weiter."
    buttonTitle="Kontakt"
    buttonLink="/Kontakt#contact-section"></ax-exclamation-box>
