<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Die Ermittlung des korrekten Wiederbeschaffungswertes im Sachverständigengutachten erfordert neben einer guten
        Marktkenntnis auch weitere Grundlagen wie beispielsweise die Handelsspannen, Besteuerungsarten und aktuelle
        Rechtsprechungen. In diesem Webinar ermitteln wir den Wiederbeschaffungswert an praktischen Beispielen.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die Grundlagen zum Wiederbeschaffungswert erwerben oder vertiefen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
