import { afterNextRender, Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { registerSmoothScrollingForRelativeLinks } from '../../../shared/lib/smooth-scrolling';
import { FormsModule } from '@angular/forms';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { NgClass } from '@angular/common';
import { initializeTooltipster } from '../../../shared/lib/initialize-tooltipster';
import {
    ADDITIONAL_USERS_FEE,
    additionalRentalCarPricingCalls,
    additionalRentalCarPricingCallsPrice,
    additionalUsers,
    additionalUsersPrice,
    ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION,
    ANNUAL_CALLS_INCLUDED_FLATRATE,
    ANNUAL_CALLS_INCLUDED_RENTAL_CAR,
    ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION,
    ANNUAL_CALLS_INCLUDED_STARTER_VALUATION,
    basicAdditionalCallsCalculation,
    basicAdditionalCallsCalculationPrice,
    basicAdditionalCallsValuation,
    basicAdditionalCallsValuationPrice,
    basicAnnualTotal,
    basicFirstYearFee,
    basicFirstYearMonthlyFee,
    basicMonthlyTotal,
    CALL_PRICE_BASIC_CALCULATION,
    CALL_PRICE_BASIC_VALUATION,
    CALL_PRICE_FLATRATE_FROM_1151,
    CALL_PRICE_FLATRATE_FROM_751,
    CALL_PRICE_FLATRATE_FROM_851,
    CALL_PRICE_FLATRATE_FROM_951,
    CALL_PRICE_PERCASE_CALCULATION,
    CALL_PRICE_PERCASE_VALUATION,
    CALL_PRICE_RENTAL_CAR,
    CALL_PRICE_STARTER_CALCULATION,
    CALL_PRICE_STARTER_VALUATION,
    DAT_LICENSE,
    DAT_SETUP_AND_INSTRUCTION,
    DAT_SETUP_WITHOUT_INSTRUCTION,
    flatrateAdditionalCalls,
    flatrateAdditionalCallsPrice,
    flatrateAnnualTotal,
    flatrateFirstYearAnnualFee,
    flatrateFirstYearMonthlyFee,
    flatrateMonthlyTotal,
    lowestApplicableCalculationPrice,
    lowestFirstYearFee,
    MAXIMUM_NUMBER_OF_MONTHLY_CASES_STARTER,
    MONTHLY_AZT_PAINT_SYSTEM_FEE,
    MONTHLY_BASE_FEE_BASIC,
    MONTHLY_BASE_FEE_FLATRATE,
    MONTHLY_BASE_FEE_PERCASE,
    MONTHLY_PAYMENT_CYCLE_FEE,
    perCaseAdditionalCallsCalculation,
    perCaseAdditionalCallsCalculationPrice,
    perCaseAdditionalCallsValuation,
    perCaseAdditionalCallsValuationPrice,
    perCaseAnnualTotal,
    perCaseFirstYearFee,
    perCaseFirstYearMonthlyFee,
    perCaseMonthlyTotal,
    perCasePlanAvailable,
    RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE,
    STARTER_SETUP_FEE,
    starterAdditionalCallsCalculation,
    starterAdditionalCallsCalculationPrice,
    starterAdditionalCallsValuation,
    starterAdditionalCallsValuationPrice,
    starterFirstYearFee,
    starterFirstYearMonthlyFee,
    starterPackageEquivalentValue,
    starterPackageSavingsInPercent,
    starterPlanAvailable,
    USERS_INCLUDED,
    VIN_CALL_PRICE,
    vinCalls,
    vinCallsPrice,
    WEBINAR_PRICE,
} from '../../../shared/lib/price-calculation/dat-price-calculator-functions';
import { formatPrice, parseNumber, roundNumber } from '../../../shared/lib/price-calculation/price-calculator-utils';
import { ActivatedRoute } from '@angular/router';
import {
    NUMBER_OF_USERS_QUERY_PARAM_NAME,
    REPORTS_PER_MONTH_QUERY_PARAM_NAME,
} from '../../../shared/lib/price-calculation/autoixpert-price-calculator-constants';

@Component({
    selector: 'ax-dat-preisrechner',
    standalone: true,
    imports: [FormsModule, NgClass],
    templateUrl: './dat-preisrechner.component.html',
    styleUrl: './dat-preisrechner.component.scss',
    animations: [fadeInAndOutAnimation(500)],
})
export class DatPreisrechnerComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly activatedRoute = inject(ActivatedRoute);

    // data inputs
    protected reportsPerMonth: string | null = null;
    protected valuationsPerMonth: string | null = null;
    protected includeRentalCarPricingService = false;
    protected alreadyDatCustomer = false;
    protected numberOfUsers = 1;
    protected paymentCycleMonthly = true;

    // view
    protected calculationInputsHaveBeenCompleteOnce = false;

    // Contact form
    protected organization = null;
    protected firstName = null;
    protected lastName = null;
    protected email = null;
    protected streetAndHouseNumber = null;
    protected zip = null;
    protected city = null;
    protected phone = null;
    protected submitUsageData = true;

    protected contactFormSending = false;
    protected contactFormSent = false;

    constructor() {
        this.titleService.setTitle('SilverDAT Preisrechner | DAT-Kalkulation Kosten');
        this.metaService.addTag({
            name: 'description',
            content:
                'Für Kfz-Gutachter: Berechnen Sie die Preise für Ihren Zugang zu SilverDAT 3. In Kooperation mit autoiXpert, der Software für Kfz-Sachverständige.',
        });

        afterNextRender(() => {
            registerSmoothScrollingForRelativeLinks();

            // Prefill the number of reports and users in case the user comes from the total cost calculator.
            const numReportsFromQueryParams = this.activatedRoute.snapshot.queryParamMap.get(
                REPORTS_PER_MONTH_QUERY_PARAM_NAME,
            );

            if (numReportsFromQueryParams) {
                this.reportsPerMonth = numReportsFromQueryParams;
                this.numberOfUsers = parseInt(
                    this.activatedRoute.snapshot.queryParamMap.get(NUMBER_OF_USERS_QUERY_PARAM_NAME),
                    0,
                );
            }
        });
    }

    //*****************************************************************************
    //  Calculator Inputs
    //****************************************************************************/
    protected handleArrowUpAndDownKeys(
        keydownEvent: KeyboardEvent,
        associatedProperty: 'reportsPerMonth' | 'valuationsPerMonth',
    ): void {
        let summand = 0;
        if (keydownEvent.key === 'ArrowDown') {
            summand = -1;
        }
        if (keydownEvent.key === 'ArrowUp') {
            summand = 1;
        }

        if (!summand) return;

        if (associatedProperty === 'reportsPerMonth') {
            this.reportsPerMonth = Math.max(parseInt(this.reportsPerMonth ?? '0', 10) + summand, 0).toString();
        }
        if (associatedProperty === 'valuationsPerMonth') {
            this.valuationsPerMonth = Math.max(parseInt(this.valuationsPerMonth ?? '0', 10) + summand, 0).toString();
        }

        this.initializeTooltips();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculator Inputs
    /////////////////////////////////////////////////////////////////////////////*/
    //*****************************************************************************
    //  Number of Users
    //****************************************************************************/
    protected decreaseUserCount(): void {
        if (this.numberOfUsers > 1) {
            this.numberOfUsers--;
        }
    }

    protected increaseUserCount(): void {
        this.numberOfUsers++;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Number of Users
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltipster
    //****************************************************************************/

    //*****************************************************************************
    //  Calculation Delay
    //****************************************************************************/
    /**
     * The calculation is actually instant but to give the user a better feeling, add a small delay
     */
    protected initializeTooltips(): void {
        if (!this.calculationInputsComplete()) return;

        // Give Vue time to display the columns with tooltips before initializing them
        window.setTimeout(initializeTooltipster);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculation Delay
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltipster
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Best Value Plan
    //****************************************************************************/
    protected isBestValuePlan(planName: 'perCase' | 'starter' | 'basic' | 'flatrate'): boolean {
        let planFee: number;

        switch (planName) {
            case 'perCase':
                planFee = perCaseFirstYearFee(
                    this.numberOfUsers,
                    this.reportsPerMonth,
                    this.valuationsPerMonth,
                    this.includeRentalCarPricingService,
                );
                break;
            case 'starter':
                planFee = starterFirstYearFee(
                    this.reportsPerMonth,
                    this.valuationsPerMonth,
                    this.includeRentalCarPricingService,
                );
                break;
            case 'basic':
                planFee = basicFirstYearFee(
                    this.numberOfUsers,
                    this.reportsPerMonth,
                    this.valuationsPerMonth,
                    this.includeRentalCarPricingService,
                );
                break;
            case 'flatrate':
                planFee = flatrateFirstYearAnnualFee(
                    this.numberOfUsers,
                    this.reportsPerMonth,
                    this.valuationsPerMonth,
                    this.includeRentalCarPricingService,
                );
                break;
        }

        return (
            planFee ===
            lowestFirstYearFee(
                this.numberOfUsers,
                this.reportsPerMonth,
                this.valuationsPerMonth,
                this.includeRentalCarPricingService,
                this.alreadyDatCustomer,
            )
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Best Value Plan
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payment Cycle
    //****************************************************************************/
    protected setPaymentToMonthly(): void {
        this.paymentCycleMonthly = true;
    }

    protected setPaymentToAnnually(): void {
        this.paymentCycleMonthly = false;
    }

    protected toggleMonthlyAndAnnually(clickEvent: MouseEvent): void {
        this.paymentCycleMonthly = !this.paymentCycleMonthly;
        // Prevent the switch background from being triggered on the same click event
        clickEvent.stopPropagation();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payment Cycle
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Form
    //****************************************************************************/
    protected sendContactForm(): void {
        if (!this.datContactFormComplete()) return;
        if (this.contactFormSending) return;

        let data = {
            organization: this.organization,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            streetAndHouseNumber: this.streetAndHouseNumber,
            zip: this.zip,
            city: this.city,
            phone: this.phone,
            submitUsageData: this.submitUsageData,
            paymentCycleMonthly: this.paymentCycleMonthly,
        };

        if (this.submitUsageData) {
            data = Object.assign({}, data, {
                reportsPerMonth: this.reportsPerMonth,
                valuationsPerMonth: this.valuationsPerMonth,
                includeRentalCarPricingService: this.includeRentalCarPricingService,
                alreadyDatCustomer: this.alreadyDatCustomer,
                numberOfUsers: this.numberOfUsers,
            });
        }

        this.contactFormSending = true;

        $.ajax({
            method: 'POST',
            url: '/api/v0/datContactForm',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success: () => {
                this.contactFormSent = true;
            },
            error: (response) => {
                alert(
                    'Das Formular konnte leider nicht versendet werden. Versuche es später erneut oder kontaktiere die autoiXpert-Hotline.',
                );
                const error = JSON.parse(response.responseText);
                console.error('Sending the DAT price calculator form to the server failed.', error);
            },
            complete: () => {
                this.contactFormSending = false;
            },
        });
    }

    protected getCityByZip(): void {
        if ((this.zip || '').length < 5) return;

        $.ajax({
            url: '/api/v0/zipCode/' + this.zip,
            success: (zipCityMaps) => {
                if (zipCityMaps[0]) {
                    this.city = zipCityMaps[0].city;
                }
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Form
    /////////////////////////////////////////////////////////////////////////////*/

    protected openHomepage(): void {
        location.href = '/';
    }

    protected calculationInputsComplete(): boolean {
        // As soon as the inputs have been completed once, display the result set to prevent
        // flickering when the user changes the values by using the delete key on his keyboard
        if (this.calculationInputsHaveBeenCompleteOnce) {
            return true;
        }
        // If the values have never been completed, check again and store the value.
        this.calculationInputsHaveBeenCompleteOnce =
            (this.reportsPerMonth !== null &&
                this.reportsPerMonth !== '' &&
                !isNaN(parseInt(this.reportsPerMonth, 10))) ||
            (this.valuationsPerMonth !== null &&
                this.valuationsPerMonth !== '' &&
                !isNaN(parseInt(this.valuationsPerMonth, 10)));
        return this.calculationInputsHaveBeenCompleteOnce;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Starter Package Equivalent Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltips
    //****************************************************************************/
    protected vinTooltip() {
        return `Für jede Kalkulation und für jede Bewertung wird eine VIN-Abfrage benötigt. ${
            this.reportsPerMonth
        } (Kalkulation) + ${this.valuationsPerMonth} (Bewertung) = ${
            parseNumber(this.reportsPerMonth) + parseNumber(this.valuationsPerMonth)
        }`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltips
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Form
    //****************************************************************************/
    protected datContactFormComplete() {
        return (
            this.organization &&
            this.firstName &&
            this.lastName &&
            this.email &&
            this.streetAndHouseNumber &&
            this.zip &&
            this.city &&
            this.phone
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Form
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly formatPrice = formatPrice;
    protected readonly starterPlanAvailable = starterPlanAvailable;
    protected readonly starterFirstYearMonthlyFee = starterFirstYearMonthlyFee;
    protected readonly starterFirstYearFee = starterFirstYearFee;
    protected readonly starterPackageEquivalentValue = starterPackageEquivalentValue;
    protected readonly starterPackageSavingsInPercent = starterPackageSavingsInPercent;
    protected readonly roundNumber = roundNumber;
    protected readonly CALL_PRICE_STARTER_VALUATION = CALL_PRICE_STARTER_VALUATION;
    protected readonly CALL_PRICE_STARTER_CALCULATION = CALL_PRICE_STARTER_CALCULATION;
    protected readonly VIN_CALL_PRICE = VIN_CALL_PRICE;
    protected readonly ANNUAL_CALLS_INCLUDED_STARTER_VALUATION = ANNUAL_CALLS_INCLUDED_STARTER_VALUATION;
    protected readonly CALL_PRICE_RENTAL_CAR = CALL_PRICE_RENTAL_CAR;
    protected readonly ANNUAL_CALLS_INCLUDED_RENTAL_CAR = ANNUAL_CALLS_INCLUDED_RENTAL_CAR;
    protected readonly ADDITIONAL_USERS_FEE = ADDITIONAL_USERS_FEE;
    protected readonly USERS_INCLUDED = USERS_INCLUDED;
    protected readonly DAT_SETUP_AND_INSTRUCTION = DAT_SETUP_AND_INSTRUCTION;
    protected readonly WEBINAR_PRICE = WEBINAR_PRICE;
    protected readonly DAT_LICENSE = DAT_LICENSE;
    protected readonly DAT_SETUP_WITHOUT_INSTRUCTION = DAT_SETUP_WITHOUT_INSTRUCTION;
    protected readonly additionalUsersPrice = additionalUsersPrice;
    protected readonly additionalUsers = additionalUsers;
    protected readonly flatrateAdditionalCallsPrice = flatrateAdditionalCallsPrice;
    protected readonly flatrateAdditionalCalls = flatrateAdditionalCalls;
    protected readonly additionalRentalCarPricingCallsPrice = additionalRentalCarPricingCallsPrice;
    protected readonly additionalRentalCarPricingCalls = additionalRentalCarPricingCalls;
    protected readonly RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE = RENTAL_CAR_PRICING_SERVICE_MONTHLY_FEE;
    protected readonly MONTHLY_AZT_PAINT_SYSTEM_FEE = MONTHLY_AZT_PAINT_SYSTEM_FEE;
    protected readonly vinCallsPrice = vinCallsPrice;
    protected readonly vinCalls = vinCalls;
    protected readonly MONTHLY_BASE_FEE_FLATRATE = MONTHLY_BASE_FEE_FLATRATE;
    protected readonly flatrateAnnualTotal = flatrateAnnualTotal;
    protected readonly flatrateFirstYearAnnualFee = flatrateFirstYearAnnualFee;
    protected readonly MONTHLY_PAYMENT_CYCLE_FEE = MONTHLY_PAYMENT_CYCLE_FEE;
    protected readonly flatrateMonthlyTotal = flatrateMonthlyTotal;
    protected readonly flatrateFirstYearMonthlyFee = flatrateFirstYearMonthlyFee;
    protected readonly CALL_PRICE_FLATRATE_FROM_1151 = CALL_PRICE_FLATRATE_FROM_1151;
    protected readonly CALL_PRICE_FLATRATE_FROM_951 = CALL_PRICE_FLATRATE_FROM_951;
    protected readonly CALL_PRICE_FLATRATE_FROM_851 = CALL_PRICE_FLATRATE_FROM_851;
    protected readonly CALL_PRICE_FLATRATE_FROM_751 = CALL_PRICE_FLATRATE_FROM_751;
    protected readonly ANNUAL_CALLS_INCLUDED_FLATRATE = ANNUAL_CALLS_INCLUDED_FLATRATE;
    protected readonly basicAdditionalCallsValuationPrice = basicAdditionalCallsValuationPrice;
    protected readonly CALL_PRICE_BASIC_VALUATION = CALL_PRICE_BASIC_VALUATION;
    protected readonly parseNumber = parseNumber;
    protected readonly basicAdditionalCallsValuation = basicAdditionalCallsValuation;
    protected readonly basicAdditionalCallsCalculationPrice = basicAdditionalCallsCalculationPrice;
    protected readonly CALL_PRICE_BASIC_CALCULATION = CALL_PRICE_BASIC_CALCULATION;
    protected readonly basicAdditionalCallsCalculation = basicAdditionalCallsCalculation;
    protected readonly MONTHLY_BASE_FEE_BASIC = MONTHLY_BASE_FEE_BASIC;
    protected readonly basicAnnualTotal = basicAnnualTotal;
    protected readonly basicFirstYearFee = basicFirstYearFee;
    protected readonly basicMonthlyTotal = basicMonthlyTotal;
    protected readonly ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION = ANNUAL_CALLS_INCLUDED_BASIC_CALCULATION;
    protected readonly basicFirstYearMonthlyFee = basicFirstYearMonthlyFee;
    protected readonly starterAdditionalCallsValuationPrice = starterAdditionalCallsValuationPrice;
    protected readonly starterAdditionalCallsValuation = starterAdditionalCallsValuation;
    protected readonly starterAdditionalCallsCalculationPrice = starterAdditionalCallsCalculationPrice;
    protected readonly starterAdditionalCallsCalculation = starterAdditionalCallsCalculation;
    protected readonly STARTER_SETUP_FEE = STARTER_SETUP_FEE;
    protected readonly ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION = ANNUAL_CALLS_INCLUDED_STARTER_CALCULATION;
    protected readonly MAXIMUM_NUMBER_OF_MONTHLY_CASES_STARTER = MAXIMUM_NUMBER_OF_MONTHLY_CASES_STARTER;
    protected readonly perCaseAdditionalCallsValuationPrice = perCaseAdditionalCallsValuationPrice;
    protected readonly MONTHLY_BASE_FEE_PERCASE = MONTHLY_BASE_FEE_PERCASE;
    protected readonly CALL_PRICE_PERCASE_VALUATION = CALL_PRICE_PERCASE_VALUATION;
    protected readonly perCaseAdditionalCallsValuation = perCaseAdditionalCallsValuation;
    protected readonly perCaseAdditionalCallsCalculationPrice = perCaseAdditionalCallsCalculationPrice;
    protected readonly perCaseAdditionalCallsCalculation = perCaseAdditionalCallsCalculation;
    protected readonly CALL_PRICE_PERCASE_CALCULATION = CALL_PRICE_PERCASE_CALCULATION;
    protected readonly perCaseFirstYearFee = perCaseFirstYearFee;
    protected readonly perCaseAnnualTotal = perCaseAnnualTotal;
    protected readonly perCaseMonthlyTotal = perCaseMonthlyTotal;
    protected readonly perCaseFirstYearMonthlyFee = perCaseFirstYearMonthlyFee;
    protected readonly lowestApplicableCalculationPrice = lowestApplicableCalculationPrice;
    protected readonly perCasePlanAvailable = perCasePlanAvailable;
}
