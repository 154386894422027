import { afterNextRender, Component, inject } from '@angular/core';
import { registerSmoothScrollingForRelativeLinks } from '../../../shared/lib/smooth-scrolling';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { initializeTooltipster } from '../../../shared/lib/initialize-tooltipster';

@Component({
    selector: 'ax-gtmotive-preise',
    standalone: true,
    imports: [FormsModule],
    templateUrl: './gtmotive-preise.component.html',
    styleUrl: './gtmotive-preise.component.scss',
})
export class GtmotivePreiseComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    // data inputs
    protected reportsPerMonth: string | null = null;

    // Monthly Fees
    protected FLEX_PLAN_MONTHLY_BASE_FEE = 32.9;
    // VIN
    protected VIN_CALL_PRICE = 1.5;
    // Calls included
    protected MONTHLY_CALCULATIONS_INCLUDED_IN_FLEX_PLAN = 4;
    protected NUMBER_OF_CALCULATIONS_FOR_CUSTOM_RATE_IN_FLATRATE = 650;
    // Usage Prices
    protected CALCULATION_PRICE_FLEX_PLAN = 5.6;
    protected CALCULATION_PRICE_FLATRATE_BELOW_200 = 5.6;
    protected CALCULATION_PRICE_FLATRATE_ABOVE_200 = 5.56;
    protected CALCULATION_PRICE_FLATRATE_ABOVE_300 = 5.55;
    protected CALCULATION_PRICE_FLATRATE_ABOVE_400 = 5.54;
    protected CALCULATION_PRICE_FLATRATE_ABOVE_500 = 5.45;
    protected CALCULATION_PRICE_FLATRATE_ABOVE_600 = 5.43;

    // view
    protected calculationInputsHaveBeenCompleteOnce = false;

    // Contact form
    protected organization = null;
    protected firstName = null;
    protected lastName = null;
    protected email = null;
    protected zip = null;
    protected city = null;
    protected phone = null;
    protected submitUsageData = true;

    protected contactFormSending = false;
    protected contactFormSent = false;

    constructor() {
        this.titleService.setTitle('GT Motive Preise | GT Estimate Kosten');
        this.metaService.addTag({
            name: 'description',
            content:
                'Für Kfz-Sachverständige: Berechnen Sie die Preise für die Kalkulationssoftware GT Estimate. In Kooperation mit autoiXpert, der Software für Kfz-Sachverständige.',
        });

        afterNextRender(() => {
            registerSmoothScrollingForRelativeLinks();
        });
    }

    //*****************************************************************************
    //  Calculator Inputs
    //****************************************************************************/
    protected handleArrowUpAndDownKeys(keydownEvent: KeyboardEvent, associatedProperty: 'reportsPerMonth') {
        let summand = 0;
        if (keydownEvent.key === 'ArrowDown') {
            summand = -1;
        }
        if (keydownEvent.key === 'ArrowUp') {
            summand = 1;
        }

        if (!summand) return;

        if (associatedProperty === 'reportsPerMonth') {
            this.reportsPerMonth = Math.max(this.parseNumber(this.reportsPerMonth) + summand, 0).toString();
        }

        this.initializeTooltips();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculator Inputs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltipster
    //****************************************************************************/

    //*****************************************************************************
    //  Calculation Delay
    //****************************************************************************/
    /**
     * The calculation is actually instant but to give the user a better feeling, add a small delay
     */
    protected initializeTooltips() {
        if (!this.calculationInputsComplete) return;

        // Give Vue time to display the columns with tooltips before initializing them
        window.setTimeout(initializeTooltipster);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculation Delay
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltipster
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Formatters
    //****************************************************************************/
    protected formatPrice(price: number, fractionDigits = 2) {
        return new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        })
            .format(price)
            .replace(',00', '');
    }

    protected roundNumber(number: number) {
        return Math.round(number);
    }

    protected parseNumber(text: string | null): number {
        return parseInt(text || '0', 10);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Formatters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Form
    //****************************************************************************/
    protected sendContactForm() {
        if (!this.gtmotiveContactFormComplete) return;
        if (this.contactFormSending) return;

        let data = {
            organization: this.organization,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            zip: this.zip,
            city: this.city,
            phone: this.phone,
            submitUsageData: this.submitUsageData,
        };

        if (this.submitUsageData) {
            data = Object.assign({}, data, {
                reportsPerMonth: this.reportsPerMonth,
            });
        }

        this.contactFormSending = true;

        $.ajax({
            method: 'POST',
            url: '/api/v0/gtmotiveContactForm',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success: () => {
                this.contactFormSent = true;
            },
            error: (response) => {
                alert(
                    'Das Formular konnte leider nicht versendet werden. Versuche es später erneut oder kontaktiere die autoiXpert-Hotline.',
                );
                const error = JSON.parse(response.responseText);
                console.error('Sending the GT Motive price calculator form to the server failed.', error);
            },
            complete: () => {
                this.contactFormSending = false;
            },
        });
    }

    protected getCityByZip() {
        if ((this.zip || '').length < 5) return;

        $.ajax({
            url: '/api/v0/zipCode/' + this.zip,
            success: (zipCityMaps) => {
                if (zipCityMaps[0]) {
                    this.city = zipCityMaps[0].city;
                }
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Form
    /////////////////////////////////////////////////////////////////////////////*/

    protected openHomepage() {
        location.href = '/';
    }

    protected calculationInputsComplete() {
        // As soon as the inputs have been completed once, display the result set to prevent
        // flickering when the user changes the values by using the delete key on his keyboard
        if (this.calculationInputsHaveBeenCompleteOnce) {
            return true;
        }
        // If the values have never been completed, check again and store the value.
        this.calculationInputsHaveBeenCompleteOnce =
            this.reportsPerMonth !== null &&
            this.reportsPerMonth !== '' &&
            !isNaN(this.parseNumber(this.reportsPerMonth));
        return this.calculationInputsHaveBeenCompleteOnce;
    }

    //*****************************************************************************
    //  Usage Prices
    //****************************************************************************/
    protected vinCalls(): number {
        return this.parseNumber(this.reportsPerMonth);
    }

    protected vinCallsPrice() {
        return this.vinCalls() * this.VIN_CALL_PRICE;
    }

    //*****************************************************************************
    //  Flex Prices
    //****************************************************************************/
    protected flexPlanAdditionalCallsCalculation() {
        return Math.max(this.parseNumber(this.reportsPerMonth) - this.MONTHLY_CALCULATIONS_INCLUDED_IN_FLEX_PLAN, 0);
    }

    protected flexPlanAdditionalCallsCalculationPrice() {
        return this.flexPlanAdditionalCallsCalculation() * this.CALCULATION_PRICE_FLEX_PLAN;
    }

    protected flexPlanMonthlyTotal() {
        return this.FLEX_PLAN_MONTHLY_BASE_FEE + this.vinCallsPrice() + this.flexPlanAdditionalCallsCalculationPrice();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Flex Prices
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Flatrate Prices
    //****************************************************************************/
    /**
     * Depending on the number of calculations, the user gets a different price.
     */
    protected flatratePricePerCalculation() {
        // Above 650 reports per month, we display an additional info message that the user is eligible for custom pricing.

        const numReportsPerYear = this.parseNumber(this.reportsPerMonth) * 12;

        if (numReportsPerYear > 600) {
            return this.CALCULATION_PRICE_FLATRATE_ABOVE_600;
        }
        if (numReportsPerYear > 500) {
            return this.CALCULATION_PRICE_FLATRATE_ABOVE_500;
        }
        if (numReportsPerYear > 400) {
            return this.CALCULATION_PRICE_FLATRATE_ABOVE_400;
        }
        if (numReportsPerYear > 300) {
            return this.CALCULATION_PRICE_FLATRATE_ABOVE_300;
        }
        if (numReportsPerYear > 200) {
            return this.CALCULATION_PRICE_FLATRATE_ABOVE_200;
        }
        return this.CALCULATION_PRICE_FLATRATE_BELOW_200;
    }

    protected flatrateCalculationsPrice() {
        return this.parseNumber(this.reportsPerMonth) * this.flatratePricePerCalculation();
    }

    protected flatrateMonthlyTotal() {
        return this.vinCallsPrice() + this.flatrateCalculationsPrice();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Flatrate Prices
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Usage Prices
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Form
    //****************************************************************************/
    protected gtmotiveContactFormComplete() {
        return (
            this.organization && this.firstName && this.lastName && this.email && this.zip && this.city && this.phone
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Form
    /////////////////////////////////////////////////////////////////////////////*/
}
