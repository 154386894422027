<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Die Bewertung eines Fahrzeugs zählt zu den grundlegenden Tätigkeiten eines Sachverständigen. Neben umfangreicher
        Kenntnis der Wertdefinition gehört ebenso das Wissen hinsichtlich der Bewertungsmöglichkeiten zum grundlegenden
        Handwerkszeug. Das Seminar vermittelt neben einer Wiederholung der Grundlagen der Bewertung auch die
        Durchführung von Fahrzeugbewertungen an praktischen Beispielen.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die die Grundlagen zum Thema Fahrzeugbewertung kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
