import { afterNextRender, Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { registerSmoothScrollingForRelativeLinks } from '../../../shared/lib/smooth-scrolling';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { fadeInAnimation } from '../../../shared/animations/fade-in.animation';
import { initializeTooltipster } from '../../../shared/lib/initialize-tooltipster';
import {
    AUDATEX_PACKAGES,
    AudatexCasePackage,
    bestValueAudatexPackage,
    caclulateAnnualFee,
    caclulateMonthlyFee,
    getAdditionalCasesPerMonth,
    getAdditionalCasesPerYear,
} from '../../../shared/lib/price-calculation/audatex-price-calculator-functions';
import {
    formatNumber,
    formatPrice,
    roundNumber,
    roundToHalf,
} from '../../../shared/lib/price-calculation/price-calculator-utils';
import { ActivatedRoute } from '@angular/router';
import { REPORTS_PER_MONTH_QUERY_PARAM_NAME } from '../../../shared/lib/price-calculation/autoixpert-price-calculator-constants';

@Component({
    selector: 'ax-audatex-preisrechner',
    standalone: true,
    imports: [FormsModule, NgClass],
    templateUrl: './audatex-preisrechner.component.html',
    styleUrl: './audatex-preisrechner.component.scss',
    animations: [fadeInAnimation()],
})
export class AudatexPreisrechnerComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);
    protected readonly activatedRoute = inject(ActivatedRoute);

    // data inputs
    protected reportsPerMonth: string = '';
    protected paymentCycleMonthly = true;

    // view
    protected calculationInputsHaveBeenCompleteOnce = false;

    // Contact form
    protected organization = null;
    protected firstName = null;
    protected lastName = null;
    protected email = null;
    protected streetAndHouseNumber = null;
    protected zip = null;
    protected city = null;
    protected phone = null;
    protected submitUsageData = true;

    protected contactFormSending = false;
    protected contactFormSent = false;

    constructor() {
        this.titleService.setTitle('Audatex Qapter Preisrechner | Audatex-Kalkulation Kosten');
        this.metaService.addTag({
            name: 'description',
            content:
                'Für Kfz-Gutachter: Berechnen Sie die Preise für Ihren Zugang zu Qapter von Audatex. In Kooperation mit autoiXpert, der Software für Kfz-Sachverständige.',
        });

        afterNextRender(() => {
            registerSmoothScrollingForRelativeLinks();

            // Prefill the number of reports in case the user comes from the total cost calculator.
            const numReportsFromQueryParams = this.activatedRoute.snapshot.queryParamMap.get(
                REPORTS_PER_MONTH_QUERY_PARAM_NAME,
            );

            if (numReportsFromQueryParams) {
                this.reportsPerMonth = numReportsFromQueryParams;
            }
        });
    }

    //*****************************************************************************
    //  Calculator Inputs
    //****************************************************************************/
    protected handleArrowUpAndDownKeys(keydownEvent: KeyboardEvent, associatedProperty: 'reportsPerMonth') {
        let summand = 0;
        if (keydownEvent.key === 'ArrowDown') {
            summand = -1;
        }
        if (keydownEvent.key === 'ArrowUp') {
            summand = 1;
        }

        if (!summand) return;

        if (associatedProperty === 'reportsPerMonth') {
            const reportsPerMonthAsNum = parseInt(this.reportsPerMonth, 10) || 0;
            this.reportsPerMonth = `${Math.max(reportsPerMonthAsNum + summand, 0)}`;
        }

        this.initializeTooltips();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculator Inputs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltipster
    //****************************************************************************/

    //*****************************************************************************
    //  Calculation Delay
    //****************************************************************************/
    /**
     * The calculation is actually instant but to give the user a better feeling, add a small delay
     */
    protected initializeTooltips() {
        if (!this.calculationInputsComplete()) return;

        // Give Vue time to display the columns with tooltips before initializing them
        window.setTimeout(initializeTooltipster);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculation Delay
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltipster
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payment Cycle
    //****************************************************************************/
    protected setPaymentToMonthly() {
        this.paymentCycleMonthly = true;
    }

    protected setPaymentToAnnually() {
        this.paymentCycleMonthly = false;
    }

    protected toggleMonthlyAndAnnually(clickEvent: MouseEvent) {
        this.paymentCycleMonthly = !this.paymentCycleMonthly;
        // Prevent the switch background from being triggered on the same click event
        clickEvent.stopPropagation();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payment Cycle
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Form
    //****************************************************************************/
    protected sendContactForm() {
        if (!this.contactFormComplete()) return;
        if (this.contactFormSending) return;

        let data = {
            organization: this.organization,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            streetAndHouseNumber: this.streetAndHouseNumber,
            zip: this.zip,
            city: this.city,
            phone: this.phone,
            submitUsageData: this.submitUsageData,
            paymentCycleMonthly: this.paymentCycleMonthly,
        };

        if (this.submitUsageData) {
            data = Object.assign({}, data, {
                reportsPerMonth: this.reportsPerMonth,
            });
        }

        this.contactFormSending = true;

        $.ajax({
            method: 'POST',
            url: '/api/v0/audatexContactForm',
            contentType: 'application/json',
            data: JSON.stringify(data),
            success: () => {
                this.contactFormSent = true;
            },
            error: (response) => {
                alert(
                    'Das Formular konnte leider nicht versendet werden. Versuche es später erneut oder kontaktiere die autoiXpert-Hotline.',
                );
                const error = JSON.parse(response.responseText);
                console.error('Sending the Audatex price calculator form to the server failed.', error);
            },
            complete: () => {
                this.contactFormSending = false;
            },
        });
    }

    protected getCityByZip() {
        if ((this.zip || '').length < 5) return;

        $.ajax({
            url: '/api/v0/zipCode/' + this.zip,
            success: (zipCityMaps) => {
                if (zipCityMaps[0]) {
                    this.city = zipCityMaps[0].city;
                }
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Form
    /////////////////////////////////////////////////////////////////////////////*/

    protected reportsPerMonthAsNumber(): number {
        return parseInt(this.reportsPerMonth, 10) || 0;
    }

    protected calculationInputsComplete() {
        const reportsPerMonthAsNumber = parseInt(this.reportsPerMonth, 10);

        // As soon as the inputs have been completed once, display the result set to prevent
        // flickering when the user changes the values by using the delete key on his keyboard
        if (this.calculationInputsHaveBeenCompleteOnce) {
            return true;
        }
        // If the values have never been completed, check again and store the value.
        this.calculationInputsHaveBeenCompleteOnce = this.reportsPerMonth !== '' && !isNaN(reportsPerMonthAsNumber);
        return this.calculationInputsHaveBeenCompleteOnce;
    }

    //*****************************************************************************
    //  Contact Form
    //****************************************************************************/
    protected contactFormComplete(): boolean {
        return !!(
            this.organization &&
            this.firstName &&
            this.lastName &&
            this.email &&
            this.streetAndHouseNumber &&
            this.zip &&
            this.city &&
            this.phone
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Form
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Visible Packages
    //****************************************************************************/
    /**
     * We only display the best value plus the one lower and the one higher.
     *
     * If either side would be empty, display the next higher packages.
     */
    protected visiblePackages(): Array<AudatexCasePackage> {
        const reportsPerMonthAsNumber = parseInt(this.reportsPerMonth, 10) || 0;
        const bestValuePackage = bestValueAudatexPackage(reportsPerMonthAsNumber);
        if (!bestValuePackage) return [];

        const visiblePackages = [bestValuePackage];
        const bestValuePackageIndex = AUDATEX_PACKAGES.indexOf(bestValuePackage);

        const lowerPackages: AudatexCasePackage[] = AUDATEX_PACKAGES.slice(0, bestValuePackageIndex);
        const higherPackages: AudatexCasePackage[] = AUDATEX_PACKAGES.slice(bestValuePackageIndex + 1);

        const bestLowerPackage = bestValueAudatexPackage(reportsPerMonthAsNumber, lowerPackages);
        const bestHigherPackage = bestValueAudatexPackage(reportsPerMonthAsNumber, higherPackages);
        if (bestLowerPackage) {
            visiblePackages.unshift(bestLowerPackage);
        }
        if (bestHigherPackage) {
            visiblePackages.push(bestHigherPackage);
        }
        // After having added the direct neighbors, add fillers.
        if (!bestLowerPackage) {
            visiblePackages.push(AUDATEX_PACKAGES[AUDATEX_PACKAGES.indexOf(bestHigherPackage) + 1]);
        }
        if (!bestHigherPackage) {
            visiblePackages.unshift(AUDATEX_PACKAGES[AUDATEX_PACKAGES.indexOf(bestLowerPackage) - 1]);
        }
        return visiblePackages;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Visible Packages
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly parseInt = parseInt;
    protected readonly getAdditionalCasesPerYear = getAdditionalCasesPerYear;
    protected readonly roundToHalf = roundToHalf;
    protected readonly bestValueAudatexPackage = bestValueAudatexPackage;
    protected readonly formatNumber = formatNumber;
    protected readonly formatPrice = formatPrice;
    protected readonly roundNumber = roundNumber;
    protected readonly getAdditionalCasesPerMonth = getAdditionalCasesPerMonth;
    protected readonly caclulateMonthlyFee = caclulateMonthlyFee;
    protected readonly caclulateAnnualFee = caclulateAnnualFee;
}
