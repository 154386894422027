import { Component } from '@angular/core';

@Component({
    selector: 'ax-total-price-calculator-banner',
    standalone: true,
    imports: [],
    templateUrl: './total-price-calculator-banner.component.html',
    styleUrl: './total-price-calculator-banner.component.scss',
})
export class TotalPriceCalculatorBannerComponent {}
