import { Component, Input } from '@angular/core';
import { GetWebinarDateLongFormPipe } from '../../pipes/get-webinar-date-long-form.pipe';
import { GetWebinarTimePipe } from '../../pipes/get-webinar-time.pipe';
import { scrollElementIntoView } from '../../../../shared/lib/scroll-element-into-view';

@Component({
    selector: 'ax-webinar-hero',
    standalone: true,
    imports: [GetWebinarDateLongFormPipe, GetWebinarTimePipe],
    templateUrl: './webinar-hero.component.html',
    styleUrl: './webinar-hero.component.scss',
})
export class WebinarHeroComponent {
    @Input() webinarDate: string | null = null;
    @Input() webinarTitleShort: string = '';
    @Input() price: number = 0;
    @Input() duration: number = 0;

    protected scrollElemIntoView = scrollElementIntoView;
}
