<div id="not-found-container">
    <img src="/assets/images/not-found.jpg" id="not-found-image" alt="Page not found" />
    <div id="not-found-text">
        <h3 class="additional-headline">Oh, oh.</h3>
        <h2>Die Seite gibt's nicht</h2>
        <!-- prettier-ignore -->
        <p>
            Fragen?
            <a href="/kontakt">Kontaktiere uns</a>!
            <br/>
            Infos zum Produkt gibt's direkt auf unserer
            <a href="/">Startseite</a>.
        </p>
    </div>
</div>
