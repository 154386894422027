import { WebinarInstructor } from './components/webinar-instructor-section/webinar-instructor-section.component';

export const WEBINAR_INSTRUCTOR_RUEDIGER_MACK: WebinarInstructor = {
    name: 'Rüdiger Mack',
    imagePath: '/assets/images/people/ruediger-mack_300.jpg',
    altDescription: 'Referent & Rechtsanwalt Rüdiger Mack',
    description: ['Sozius der Reinhardt Mack Anwaltskanzlei', 'Rechtsanwalt', 'Mitglied Deutscher Anwaltverein'],
};

export const WEBINAR_INSTRUCTOR_PATRICK_KOHL: WebinarInstructor = {
    name: 'Patrick Kohl',
    displayName: 'B. Eng. Patrick Kohl',
    imagePath: '/assets/images/people/patrick-kohl.jpg',
    altDescription: 'Referent & Kfz-Sachverständiger Patrick Kohl',
    description: [
        'Geschäftsführender Gesellschafter KFZ Gutachter Süd GmbH',
        'Kfz-Sachverständiger',
        'Prüfingenieur der GTÜ mbH',
        'Unterschriftsberechtigter des Technischen Dienstes der GTÜ mbH',
        'Referent für die Kfz-Branche',
    ],
};

export const WEBINAR_INSTRUCTOR_ANDREAS_SCHLIEFER: WebinarInstructor = {
    name: 'Andreas Schliefer',
    imagePath: '/assets/images/people/andreas-sommer.jpg',
    altDescription: 'Referent & Gesellschafter von autoiXpert Andreas Schliefer',
    description: [
        'Gesellschafter von autoiXpert',
        'Experte für Dokument-Gestaltung',
        'Experte für Buchhaltung',
        'Referent für die Kfz-Branche',
    ],
};

export const WEBINAR_INSTRUCTOR_KONRAD_DEUSCHLE: WebinarInstructor = {
    name: 'Konrad Deuschle',
    displayName: 'Dipl.-Ing.(FH) Konrad Deuschle',
    imagePath: '/assets/images/people/konrad-deuschle.jpg',
    altDescription: 'Referent & Kfz-Sachverständiger Konrad Deuschle',
    description: [
        'Geschäftsführender Gesellschafter der Ingenieurbüro Deuschle GmbH',
        'Unterschriftsberechtigter des technischen Dienstes der GTÜ mbH',
        'Prüfingenieur der GTÜ mbH',
        'Classic Data Partner',
        'Referent für die Sachverständigenbranche',
    ],
};
