import { Component } from '@angular/core';

@Component({
    selector: 'ax-pricing-faq',
    standalone: true,
    imports: [],
    templateUrl: './pricing-faq.component.html',
    styleUrl: './pricing-faq.component.scss',
})
export class PricingFaqComponent {}
