import { animate, style, transition, trigger } from '@angular/animations';

export function slideInAndOutVertically(enterDuration = 200, leaveDuration = 500) {
    return trigger('slideInAndOutVertically', [
        transition(':enter', [
            style({
                height: 0,
                opacity: 0,
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: 0,
                marginBottom: 0,
            }),
            animate(`${enterDuration}ms ease`),
        ]),
        transition(':leave', [
            style({
                overflow: 'hidden',
            }),
            animate(
                `${leaveDuration}ms ease`,
                style({
                    height: 0,
                    opacity: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                }),
            ),
        ]),
    ]);
}
