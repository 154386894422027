<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        Die Auftragserteilung für ein Gutachten stellt die elementare Grundlage für die Arbeit des Sachverständigen dar.
        Eine ordnungsgemäße Auftragserteilung entscheidet regelmäßig über die Erstattungsfähigkeit der Vergütung aber
        auch über die Grundlage für weitere Themen wie beispielsweise eine Abtretungsvereinbarung.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Du solltest teilnehmen, wenn du in das Thema Erstellung von Gutachten und zwei- und dreirädrigen Fahrzeugen und
    deren Kalkulation einsteigen oder dein Grundlagenwissen auffrischen möchtest.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
