import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-impressum',
    standalone: true,
    imports: [],
    templateUrl: './impressum.component.html',
})
export class ImpressumComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Impressum');
        this.metaService.addTag({
            name: 'description',
            content:
                'Impressum der autoiXpert GmbH & Co. KG mit Sitz in Burgau. Büroanschrift: Binsentalstraße 40A in 89331 Burgau.',
        });
    }
}
