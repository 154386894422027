import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ax-teilnahmebedingungen',
    standalone: true,
    imports: [],
    templateUrl: './teilnahmebedingungen.component.html',
})
export class TeilnahmebedingungenComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Teilnahmebedingungen');
        this.metaService.addTag({
            name: 'description',
            content: 'Teilnahmebedingungen für Gewinnspiele der autoiXpert GmbH & Co. KG mit Sitz in Neu-Ulm.',
        });
    }
}
