import { Component, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ax-audatex-partnerschaft',
    standalone: true,
    imports: [],
    templateUrl: './audatex-partnerschaft.component.html',
    styleUrl: './audatex-partnerschaft.component.scss',
})
export class AudatexPartnerschaftComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    constructor() {
        this.titleService.setTitle('Strategische Partnerschaft mit Audatex | Sachverständigensoftware | Qapter-iXpert');
        this.metaService.addTag({
            name: 'description',
            content:
                'autoiXpert & Audatex sind eine strategische Partnerschaft eingegangen, um moderne Gutachtensoftware auch für Audatex-Nutzer verfügbar zu machen.',
        });
    }
}
