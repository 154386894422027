import { animate, style, transition, trigger } from '@angular/animations';

export function fadeInAndOutAnimation(duration: number = 200, delayEnter = 0, delayLeave = 0) {
    return trigger('fadeInAndOut', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate(`${duration}ms ${delayEnter}ms ease`, style({ opacity: 1 })),
        ]),
        transition(':leave', [animate(`${duration}ms ${delayLeave}ms ease`, style({ opacity: 0 }))]),
    ]);
}
