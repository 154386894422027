<section id="impressum-section">
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <div id="impressum-container">
                    <h1>Impressum</h1>
                    <strong>autoiXpert GmbH & Co. KG</strong>
                    <br />
                    <br />
                    <h3>Büroanschrift:</h3>
                    <p>
                        Binsentalstraße 40A
                        <br />
                        89331 Burgau in Bayern
                        <br />
                        E-Mail: info [at] autoixpert.de
                    </p>
                    <p>
                        Die autoiXpert GmbH &amp; Co. KG wird vertreten durch die persönlich haftende Gesellschafterin:
                        autoiXpert Software GmbH, Burgau (Amtsgericht Memmingen HRB 16943). Diese wiederum wird
                        vertreten durch den Geschäftsführer: Herr Andreas Schliefer.
                    </p>
                    <p>
                        Umsatzsteuer-Identifikationsnummer: DE308576826
                        <br />
                        Handelsregister: Amtsgerichts Memmingen
                        <br />
                        Handelsregisternummer: HRA 12925
                    </p>
                    <h2>Google Analytics</h2>
                    <p>
                        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google inc. („Google“) Google
                        Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und
                        die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten
                        Informationen über Ihre Benutzung dieser Website (Ihre IP-Adresse wird dabei verkürzt und damit
                        anonymisiert) werden an einen Server von Google in den USA übertragen und dort gespeichert.
                        Google wird diese Informationen benutzen, um die Nutzung der Website auszuwerten, um Reports
                        über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der
                        Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird
                        Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
                        vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in
                        keinem Fall Ihre anonymisierte IP-Adresse mit anderen Daten von Google in Verbindung bringen.
                        Sie können die Installation der
                        <strong>Cookies durch eine entsprechende Einstellung ihrer Browsersoftware verhindern</strong>
                        ; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                        Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website
                        erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor
                        beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
                    </p>
                    <h2>Rechtliche Hinweise</h2>
                    <p>
                        <strong>Urheberrecht</strong>
                        <br />
                        Die Seiten des Internet-Auftritts genießen urheberrechtlichen Schutz. Insbesondere
                        Vervielfältigungen und die Einspeicherung und Verarbeitung in anderen elektronischen Medien sind
                        urheberrechtlich geschützt. Nachahmung und Verwertung -auch auszugsweise- sind nur mit
                        Genehmigung durch uns statthaft. Inhalt und Struktur der Website sind urheberrechtlich
                        geschützt. Die Vervielfältigung von Informationen und Daten, insbesondere die Verwendung von
                        Texten, Textteilen oder Bildmaterial, bedarf der vorherigen schriftlichen Zustimmung durch uns.
                    </p>
                    <p>
                        <strong>Informationspflichten</strong>
                        <br />
                        Wir haben die Informationen auf den Webseiten nach bestem Wissen zusammengestellt. Sie können
                        sich jedoch jederzeit ändern, insbesondere um einer technischen bzw. wirtschaftlichen
                        Weiterentwicklung oder geänderten Einsatz- und Rahmenbedingungen Rechnung zu tragen, ohne dass
                        es einer Ankündigung oder eines Hinweises auf die Änderung bedarf.
                        <br />
                        Die Webseiten dienen allein der Information. Sie erhalten keinerlei verbindliche Garantien,
                        Gewährleistungen oder Zusicherungen. Verbindliche Aussagen können nur im Einzelfall auf eine
                        konkrete Anfrage hin getroffen werden.
                    </p>
                    <p>
                        <strong>Verlinkungen (Links)</strong>
                        <br />
                        Die Seiten des Internetauftritts erhalten auch Verknüpfungen (sog. „Hyperlinks“) zu Webseiten im
                        Internet, die von Dritten gepflegt werden und deren Inhalte uns nicht bekannt sind. Wir
                        vermitteln lediglich den Zugang zu diesen Webseiten und übernehmen keinerlei Verantwortung für
                        deren Inhalte. Unsere Links zu fremden Internetseiten dienen lediglich zur Erleichterung der
                        Navigation. Wir machen uns die auf den verlinkten Seiten dargestellten Aussagen nicht zu Eigen.
                        Insbesondere haften wir nicht für dort begangene Verstöße gegen gesetzliche Bestimmungen und
                        Rechte Dritter.
                    </p>
                    <p>
                        <strong>Sonstige Bestimmungen</strong>
                        <br />
                        Die Benutzungsbedingungen unterliegen Deutschem Recht. Gerichtsstand für Streitigkeiten, die
                        diesen Internetauftritt betreffen, ist Memmingen. Sollte eine der Bestimmungen dieser
                        Benutzungsbedingungen unwirksam sein oder werden, wird die Gültigkeit der übrigen Bestimmungen
                        davon nicht berührt.
                    </p>
                    <h2>Datenschutz</h2>
                    <p>
                        Datenschutz und Datensicherheit haben für uns eine hohe Priorität. Deshalb ist uns der Schutz
                        Ihrer personenbezogenen Daten während unserer gesamten Geschäftsprozesse ein besonderes
                        Anliegen.
                        <br />
                        Dieser Datenschutzhinweis erläutert Ihnen, welche Informationen wir während Ihres Besuchs auf
                        unseren Websites erfassen und wie diese genutzt werden. Er gilt nicht für Websites von
                        Unternehmen, die ggf. einen Link zu dieser oder einer unserer anderen Websites haben.
                    </p>
                    <p>
                        <strong>Erfassung und Verarbeitung von personenbezogenen Daten</strong>
                        <br />
                        Wenn Sie unsere Internet-Seiten besuchen, verzeichnen unsere Web-Server temporär den
                        Domain-Namen oder die IP-Adresse des anfragenden Rechners sowie das Zugriffsdatum, die
                        Dateianfrage des Clients (Dateiname und URL), den http(s)-Antwort-Code und die Internet-Seite,
                        von der aus Sie uns besuchen sowie die Anzahl der im Rahmen der Verbindung transferierten Bytes.
                        Diese Daten werden nach Beendigung der jeweiligen Internet-Sitzung gelöscht. Für bestimmte
                        Angebote setzen wir Cookies ein, die nur für die Dauer einer Serververbindung (Session) auf
                        Ihrem Computer gespeichert werden. Diese so genannten Session Cookies dienen zur Steuerung der
                        Transaktionen und enthalten für die von Ihnen aufgerufene Funktion lediglich eine anonyme
                        Transaktions-ID. Für bestimmte Angebote setzen wir Persistente Cookies ein, die der
                        Funktionalität der Website dienen sollen und für künftige Sitzungen auf Ihrem PC gespeichert
                        werden. Sie haben jederzeit die Möglichkeit dem zu widersprechen durch entsprechende
                        Einstellungen Ihres Browsers.
                    </p>
                    <p>
                        <strong>Verwendung und Weitergabe personenbezogener Daten</strong>
                        <br />
                        Wir nutzen Ihre personenbezogenen Daten nur zum Zwecke der technischen Administration der
                        Websites und zur Erfüllung Ihrer Wünsche und Anforderungen. Darüber hinausgehende
                        personenbezogene Daten, wie z.B. Ihr Name, Ihre Anschrift, Telefonnummer oder E-Mail-Adresse
                        werden nicht erfasst, es sei denn, diese Angaben werden von Ihnen freiwillig gemacht. Die von
                        Ihnen bei der Kontaktaufnahme erlangten Daten, deren Umfang Sie dem Kontaktformular entnehmen
                        können, werden ausschließlich zur Ausgestaltung der Anfragenbeantwortung verarbeitet. Eine
                        Weitergabe personenbezogener Daten an Dritte erfolgt nur nach ausdrücklicher Einwilligung des
                        Betroffenen. Unsere Vertragspartner werden von uns vertraglich verpflichtet, Ihre Daten
                        vertraulich und entsprechend den gesetzlichen Vorschriften zu behandeln. Darüber hinaus werden
                        personenbezogene Daten nicht an Dritte weitergegeben, es sei denn an staatliche Stellen, wenn
                        dies gesetzlich vorgeschrieben ist. Außerdem sind wir gemäß § 101 Urheberrechtsgesetz (UrhG)
                        nach Vorlage eines entsprechenden Gerichtsbeschlusses verpflichtet, Inhabern von Urheber- und
                        Leistungsschutzrechten Auskunft über Kunden zu geben, die urheberrechtlich geschützte Werke in
                        Internet-Tauschbörsen (sog. Filesharing) angeboten haben sollen. Unsere Auskunft enthält in
                        diesen Fällen die Benutzerkennung einer zum angefragten Datum/Uhrzeit zugeteilten IP-Adresse
                        sowie Namen und Anschrift des Kunden.
                    </p>
                    <p>
                        <strong>Vertragsdaten</strong>
                        <br />
                        Wenn Sie sich registrieren, verarbeiten und nutzen wir die bei Vertragsabschluss und während der
                        Vertragslaufzeit erhobenen Daten, die zur gegenseitigen, ordnungsgemäßen Vertragserfüllung
                        erforderlich sind sowie die freiwilligen Angaben (Vertragsdaten). Zu den Vertragsdaten gehören
                        die Anrede, Nachname und Vorname, Adresse, Geburtsdatum, Telefonnummer und / oder
                        E-Mail-Adressen, Daten über die Zahlungsabwicklung, die Umsatzdaten – differenziert nach den
                        verwendeten Diensten, Produkten und Informationen über die von Ihnen genutzte Produkte. Sollten
                        Sie weitere Benutzer einrichten, so werden auch deren Daten gespeichert. Ihre Vertragsdaten
                        werden über das Ende des Vertragsverhältnisses hinaus nur nach Maßgabe der vertraglichen
                        Vereinbarungen gespeichert und sind dabei auf das jeweils erforderliche Minimum beschränkt.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
