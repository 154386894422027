<ax-webinar-hero
    [webinarDate]="webinarDate"
    [webinarTitleShort]="webinarTitleShort"
    [price]="price"
    [duration]="duration"></ax-webinar-hero>

<ax-webinar-agenda
    [maximumNumberOfParticipants]="maximumNumberOfParticipants"
    [webinarContents]="webinarContents"
    [webinarTitle]="webinarTitle">
    <p>
        In diesem Webinar erhalten Sie einen kompakten Überblick über die Erstellung von Zustandsberichten, die auch
        Leasing-Rückläufer genannt werden. Sie lernen verschiedene Formen von Zustandsberichten kennen und erfahren, wie
        Sie die Anforderungen je nach Auftraggeber erfüllen können. Zudem erhalten Sie Einblicke in die Bewertung von
        Schäden und Beanstandungen sowie in die effiziente Nutzung von autoiXpert zur Erstellung der Berichte.
    </p>
</ax-webinar-agenda>

<ax-webinar-target-group [targetGroupLevel]="targetGroupLevel">
    Für Kfz-Sachverständige, die die Grundlagen zum Thema Zustandsberichte/Leasingrückläufer kennenlernen möchten.
</ax-webinar-target-group>

<ax-webinar-instructor-section [instructors]="instructors"></ax-webinar-instructor-section>

<ax-webinar-signup-section
    [webinarDate]="webinarDate"
    [duration]="duration"
    [price]="price"
    [webinarTitle]="webinarTitle"
    [webinarContents]="webinarContents"
    [instructor]="instructors[0].name"
    [eventOverbooked]="eventOverbooked"></ax-webinar-signup-section>
