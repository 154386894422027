<footer id="footer">
    <div class="container" id="footer-container">
        <nav id="footer-navigation" class="row justify-content-center">
            <div class="footer-navigation-group col-6 col-md-3">
                <h6>Produkt</h6>
                <ul class="footer-link-list">
                    <li><a href="/">Startseite</a></li>
                    <li><a href="/kfz-sachverstaendigen-software">Funktionen</a></li>
                    <li><a href="/preise">Preise</a></li>
                    <!-- Link to partner section so that the events are truly visible -->
                    <li><a href="/#partners-section">Webinare & Events</a></li>
                    <li>
                        <!--********** eepurl.com is MailChimp's short url domain. **********-->
                        <a href="http://eepurl.com/c1pzgL" target="_blank" rel="noopener">Newsletter</a>
                    </li>
                    <li>
                        <a href="https://wissen.autoixpert.de" target="_blank" rel="noopener">
                            Wissens-Datenbank & Hilfe
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.youtube.com/channel/UCJNnRq0fR3OnndjbMuoN30A"
                            target="_blank"
                            rel="noopener">
                            Video-Tutorials
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer-navigation-group col-6 col-md-3">
                <h6>Unternehmen</h6>
                <ul class="footer-link-list">
                    <li><a href="/team">Wer wir sind</a></li>
                    <li><a href="/kontakt">Kontakt</a></li>
                    <li><a href="/jobs">Jobs</a></li>
                </ul>
                <h6>Partner</h6>
                <ul class="footer-link-list">
                    <li><a href="/Gesamtkostenrechner">Gesamtpreisrechner</a></li>
                    <li><a href="/dat-preisrechner">DAT-Preise</a></li>
                    <li><a href="/audatex-preisrechner">Audatex-Preise</a></li>
                    <li><a href="/gtmotive-preise">GT Motive Preise</a></li>
                </ul>
            </div>
            <div class="footer-navigation-group col-6 col-md-3">
                <h6>Rechtliches</h6>
                <ul class="footer-link-list">
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/nutzungsbedingungen">Nutzungsbedingungen</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                </ul>
            </div>
            <div class="footer-navigation-group col-6 col-md-3">
                <h6>Jetzt starten</h6>
                <ul class="footer-link-list">
                    <li><a href="/registrierung">Kostenfrei testen</a></li>
                    <li>
                        <a href="https://app.{{ configService.domain }}" target="_blank" rel="noopener">Login</a>
                    </li>
                    <li><a href="/gutachter-werden">Gutachter werden</a></li>
                </ul>
            </div>
        </nav>

        <div id="end-of-page-container" class="row">
            <div id="end-of-page-left-column" class="col-md-3 text-center text-md-left">
                <a href="/">
                    <img id="footer-autoixpert-x" src="/assets/images/autoixpert-x.svg" alt="autoiXpert X" />
                </a>
            </div>
            <div id="end-of-page-center-column" class="col-md-6 text-md-center">
                <div id="company-name">© {{ configService.currentYear }} autoiXpert GmbH & Co. KG</div>
            </div>
            <div id="end-of-page-right-column" class="col-md-3 text-center text-md-right">
                Qualität aus der Region Augsburg.
            </div>
        </div>

        <div id="dont-wait-container" class="row text-center text-md-left">
            <div class="col-md-8">
                <h3 id="footer-signup-heading">Worauf wartest du noch? Die ersten 30 Tage sind kostenfrei!</h3>
                <div id="footer-signup-tagline">
                    Schließe dich jetzt hunderten von Kfz-Sachverständigen an. Audatex- & DAT-Testaccount mit 5 VINs
                    inklusive.
                </div>
            </div>
            <div class="col-md-4 text-md-right">
                <a
                    id="signup-section-button"
                    class="button-solid"
                    href="/registrierung?utm_source=autoixpert-website&utm_medium=footer-button">
                    30 Tage kostenfrei testen
                </a>
            </div>
        </div>
    </div>
</footer>
